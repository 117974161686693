import SearchResult from '../SearchResult'

/**
 * @extends {SearchResult}
 */
export default class MembershipSummaryResults extends SearchResult {
    /** @type {MembershipSummaryView[]} */
    documents;
    static definitions = {
        documents: { map: true, isListRef: true, ref: require('./MembershipSummaryView'), text: 'Documents' },
    }
}