import { Service } from '../../framework'
import { Person, Spouse} from "../../entities"
import SpouseHistoryMapper from '../../mappers/SpouseHistoryMapper';
import { isString } from 'lodash';
import { PersonService } from '..';
import UnknownPerson from '../../entities/person/UnknownPerson';
import SpouseHistory from '../../entities/person/SpouseHistory';

/**
 * @extends {Service}
 */
class SpouseService extends Service {
    constructor() {
        super(Spouse, 'Spouse', 'spouse')
    }

    GET_ACTION = 'GetSpouses';
    
    async get(personId, options) {
        if (!personId) return;
        return await this.callApi(this.GET_ACTION, this.getActionKey(personId), { id: personId }, SpouseHistoryMapper, options);
    }

    getActionKey(personId) { return this.GET_ACTION + '_' + personId }

    async delete(personId, spouseId, options) {
        return await this.deleteBy('Delete' +  this.serviceName, { personId, spouseId }, options);
    } 

    async load(spouse, options) {
        if (!spouse.person || !isString(spouse.person.id) || spouse.person.id === '') return spouse;
        const person = await PersonService.get(spouse.person.id, {...options, load: false});
        if (person) spouse.person = person
        return spouse;
    } 
    
    resetMaritalFields(spouse) {
        const defaults = new Spouse();
        const propList = [
            'person', 'taxationStatus', 'maritalBreakDownOption', 
            'judgementOrderReceivedOption', 'maritalStatusDeclarationReceived', 
            'judgementOrderReceivedOption'
        ];
        for (const prop of propList) spouse[prop] = defaults[prop];

        return spouse;
    }

    preSave(spouse) {
        spouse.touch();
        if (spouse.isSingle) return this.resetMaritalFields(spouse);
        if (spouse.noSin) spouse.person = new Person();
        else spouse.unknownPerson = new UnknownPerson();
        
        return spouse;
    }

    /**
     * Validate the spouse
     * @param {Spouse} spouse The spouse to validate
     * @param {Spouse | undefined} originalSpouse The original spouse before changes
     * @returns {{mSts?: string; jurisdiction?: string; sin?: string;}} error messages
     */
    validate(spouse, originalSpouse) {
        var errorMessages = {};
        if (!spouse.mSts) errorMessages['mSts']= 'A marital status is required.';
        if (!spouse.jurisdiction) errorMessages['jurisdiction']= 'A jurisdiction is required.';
        if (!spouse.isSingle && !spouse.noSin && !spouse.person.id) errorMessages['sin']= 'A person is required.';
        if(spouse?.mSts && spouse.isSeparated
            && (spouse?.isNew?.() || !originalSpouse.isSeparated )) 
            errorMessages['mSts']= 'Cannot change status to separated (legacy status).';

        return errorMessages;
    }

    //bulk load requires caching spouseHistories per person
    async customCache(data) {
        let people = {}
        for (let item of data) {
            let spouses = people[item.parent] ?? [];
            spouses.push(item);
            people[item.parent] = spouses;
        }
        Promise.all(Object.keys(people).map(async (personId) => {
            return this.useCache(this.getActionKey(personId), () => Promise.resolve(new SpouseHistory(people[personId])), true);
        }))
    }

	/**
	 * Remove the `spouseCheck` events (`spouInf`)
	 * @param {Participation} participation 
	 */
	async removeSpouseCheckEvent(participation,) {
        const event = participation?.events?.find?.((e) => e.config?.isSpouseCheck);
        if (!event) {
            return;
        }
        const personId = participation.person?.id;
        const spouseName = event.metadata?.spouseName;
        const spouseSIN = event.metadata?.spouseSIN;
        const params = { personId };
        if(spouseName) params.spouseName = spouseName;
        if(spouseSIN) params.spouseSIN = spouseSIN;
        await this.callApi("RemoveSpouInfEvents", `RemoveSpouInfEvents_${personId}`, params, SpouseHistoryMapper);
  }
}

const instance = new SpouseService()
export default instance

