import { Service } from '../../framework'
import PersonResults from '../../entities/search/PersonSearch/PersonResults';

/**
 * @extends {Service}
 */
class PersonSearch extends Service {
    constructor() {
        super(PersonResults, 'Person', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    /**
     * 
     * @param {object} parameters 
     * @param {number | undefined} page Default: 1
     * @param {number | undefined} pageSize Default: 15
     * @param {string} scrollDuration 
     * @param {string | undefined} scrollId 
     * @returns {Promise<PersonResults>}
     */
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }

    /**
     * Recursively search until all results are returned
     * @param {object} searchParams
     * @returns {Promise<PersonResults>}
     */
    async scrollSearch(searchParams) {
      const result = await this._scrollSearch(searchParams);
      return result;
    }
}

const instance = new PersonSearch()
export default instance

