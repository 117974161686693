import { Excel } from '../../framework/utils'
import { Report } from "../../entities/yearEnd/Report"
import { YearEndService } from "../../services"
import { moment, EXCEL_FORMAT } from '../../framework/utils/helper'
import TerminationReportBusiness from '../../business/TerminationReportBusiness';

const list = (configuration = []) => ({ type: 'list', allowBlank: true, formulae: configuration });

/**
 * Formats the string as a date (format: "M/D/YYYY") if it's a valid date, otherwise is returned as is.
 * @param {string | null | undefined} value 
 */
const formatAsDateOrString = (value) => {
    const valueFormatted = (value && moment.utc(value).isValid()) ? moment.utc(value).format(EXCEL_FORMAT) : value;
    return valueFormatted;
}

const YEAR_HEADER_ROW_INDEX = 1;
const INSTRUCTIONS_TAB = 'Instructions-READ ';
const YEAR_END_TABS = {
    MEMBER: {
        tabTitle: 'Member Data-REVIEW',
        customLoad: loadSummary,
        startIndex: 3,
    },
    PERSONAL: {
        tabTitle: 'Personal Data-VALIDATE',
        startIndex: 3,
        headerGroups: ['employmentFull', "personalValidation"],
        cellHardConfigs: {
            'R': { dataValidation: () => list(['"Male,Female"']) },
            'S': { dataValidation: () => list(['"English,French"'])},
            'T': { dataValidation: () => list(['"Yes,No"'])},
            'U': { dataValidation: () => list(['"Yes,No"'])},
            'V': { dataValidation: () => list(['"Yes,No"'])},
        }
    },
    STATUS: {
        tabTitle: 'Status-VALIDATE',
        startIndex: 3,
        headerGroups: ['employmentBrief', 'details', 'detailsFull', "statusValidation"],
        cellHardConfigs: {
            'I': { value: (sheet, row, index) => Math.min(sheet.getCell('I' + (index + 3)).value, 12)}, 
            'K': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$45:$I$63"])}, 
            'M': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$64:$I$68"])},
            'O': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$69:$I$83"])}, 
            'P': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$43:$I$78"]),
                   value: (sheet, row, index) => row.cmt?.richText ? row.cmt.richText.map(comment => comment.text).toString() : row.cmt }, 
            'Q': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AE$1:$AE$11"]),
                   value: (sheet, row, index) => row.reviewedCmt },
        },
    },
    ANNUALIZED: {
        tabTitle: 'Annualized Earnings-VALIDATE',
        startIndex: 4,
        headerGroups: ['employmentBrief', 'financialValidation', 'lastYearDetails'],
        cellHardConfigs: {
            'F': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$I$84:$I$89"])},
        },
    },
    TERMINATION: {
        tabTitle: 'Terminations-CONFIRM',
        startIndex: 5,
        headerGroups: [
            'employmentFullTermTab', 
            'personalInfoValidation', 
            'details', 
            'statusConfirmation',
            'membershipTerminationDateValidation',
            'maritalStatus',
            'pensionableEarningsValidation',
            'contributions',
            'retroactive',
            'totalCreditedService',
            'lastYearDetails',
            'personalEarningsValidation',
            'validation',
        ], 
        cellHardConfigs: {
            'T': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AD$3:$AD$5"])},
            'AA': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AD$10:$AD$16"])},
            'AC': { dataValidation: () => list(['"Yes,No"'])},
            'BH': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AD$34:$AD$35"])},
        // Keeping this in temporarily as we may need them after testing, to remove if this code goes in main
        //     'L': { dataValidation: () => list(["'"+ INSTRUCTIONS_TAB +"'!$AD$12:$AD$14"])},
        //     'M': { dataValidation: () => list(['"Yes,No"'])},

        // Needed because all comments are defined as STRING in YearEndEmploymentSummary.definitions
        // but some of them might actually be dates, so we try to format them as date if possible
        // if their definition is changed to DATE, this code can be removed
        // See src/entities/yearEnd/YearEndEmploymentSummary.js
        'P': { value: (sheet, row, index) => {
             const valueFormatted = formatAsDateOrString(row.personalDataERInitialsCmt);
             return valueFormatted;
            }
        }, 
        'U': { value: (sheet, row, index) => {
             const valueFormatted = formatAsDateOrString(row.statusERInitialsCmt);
             return valueFormatted;
            }
        }, 
        'AH': { value: (sheet, row, index) => {
             const valueFormatted = formatAsDateOrString(row.maritalStatusERInitialsCmt);
             return valueFormatted;
            }
        }, 
        'AN': { value: (sheet, row, index) => {
             const valueFormatted = formatAsDateOrString(row.peERInitialsCmt);
             return valueFormatted;
            }
        }, 
        },
        filter: (x) => x.isOnLeaveExpiredOrPending
    },
    //Custom extra tab for Termination Option in Year End
    CONTRIBUTIONS: {
        tabTitle: 'Contribution Histories',
        startIndex: 2,
        customLoad: loadContributions,
        createSheet: true,
        inclusionCondition: (reportConfiguration, reportData) => reportConfiguration?.selectedReportType === 'termination',
    },
    BENEFICIARIES: {
        tabTitle: 'Beneficiaries',
        startIndex: 2,
        createSheet: true,
        customLoad: loadBeneficiaries,
        inclusionCondition: (reportConfiguration, reportData) => reportConfiguration?.selectedReportType === 'termination',
    }
}

//Global config for Year End
const YEAR_END_CONFIG = {
    rules: [
        // We only want to see the CONTRIBUTIONS and TERMINATION tabs visibile if the reportype is termination
        ({reportConfiguration, excel}) => {
            if(reportConfiguration?.selectedReportType === 'termination') {
                // Loop through excel sheets and make all tabs hidden except CONTRIBUTIONS and TERMINATION
                Object.keys(excel.workBook.worksheets).forEach(sheetIndex => {
                    const sheet = excel.workBook.worksheets[sheetIndex]
                    if(sheet.name !== YEAR_END_TABS.CONTRIBUTIONS.tabTitle && sheet.name !== YEAR_END_TABS.TERMINATION.tabTitle && sheet.name !== YEAR_END_TABS.BENEFICIARIES.tabTitle) {
                        sheet.state = 'hidden'; // Hide the sheet
                    }
                })
            }
        },
        // Hide the instructions tab for termination report only
        ({reportConfiguration, excel}) => {
            const instructionsTab = excel.workBook.worksheets.find(sheet => sheet.name === INSTRUCTIONS_TAB);
            if(instructionsTab && reportConfiguration?.selectedReportType === 'termination') instructionsTab.state = 'hidden';
        },
    ]
}

/**
 * Loads the beneficiaries tab data into the sheet.
 * @param {Report} report data to load
 * @param {Sheet} sheet we are loading the data into
 * @param {Template} template template object
 * @param {Excel} excel excel object parent to the sheet
 */
function loadBeneficiaries(report, sheet, template, excel) {
    const maxBeneficiaries = Math.max(...report.details.map(detail => detail.beneficiaries?.length || 0));
    const headers = ['SIN', 'Name', 'ER Code', ...Array.from({ length: maxBeneficiaries }, (_, index) => `Beneficiary ${index + 1}`)];
    excel.addCustomDataRows(sheet, [{ row: headers, isBold: true }])
    if (report.details.length > 0) {
        const data = report.details.map(detail => {
            //map contributionDetails to proper year
            const beneficiaries = detail.beneficiaries.map(beneficiary => beneficiary.details);

            const row = { row: [detail.sin, detail.name, detail.code, ...beneficiaries] };
            return row;
        })
        excel.addCustomDataRows(sheet, data)
    } 
}

/**
 * Loads the contributions tab data into the sheet. Breaks down the contribution details by year
 * Should show the contributions buckets for the latest year. Should only show the contribution totals for the other years.
 * 
 * @param {Report} report data to load
 * @param {Sheet} sheet we are loading the data into
 * @param {Template} template template object
 * @param {Excel} excel excel object parent to the sheet
 */
function loadContributions(report, sheet, template, excel) {

    //headers are dynamic, we need one column per year
    // SIN, Name, ER Code, Year XXXX, Years XXXX, ... 
    //get unique list of years listed in the contributionDetails
    const contributionYears = [];
    report.details.forEach(detail => {
        detail.contributionDetails.forEach(contribution => {
            contributionYears.push(contribution.year);
        })
    })

    // Get any missing years in between the start year and end year (ex: if 2023 is missing, add it)
    const allYears = [];
    const startYear = Math.min(...contributionYears);
    const endYear = Math.max(...contributionYears);
    for (let i = startYear; i <= endYear; i++) allYears.push(i);

    // Sort in descending order (ex: 2024, 2023, 2022, ...)
    const orderedUniqueYears = [...new Set(allYears)].sort((a, b) => b - a);
    const infoHeaders = ['SIN', 'Name', 'ER Code'];
    const contributionBuckets = TerminationReportBusiness.contributionBuckets;

    let rows = [];
    rows.push({row: ['', '', '', orderedUniqueYears[0]], isBold: true}); // First row with the latest year

    const headers = [...infoHeaders, ...contributionBuckets, 
        ...orderedUniqueYears.slice(1).map(year => "Total Contributions")
    ];
    rows.push({row: headers, isBold: true}); // Second row with headers
    excel.addCustomDataRows(sheet, rows);

    // Row with year headers
    const headerRow = sheet._rows.find(x => x.values[1] === infoHeaders[0]);

    const latestYearBucketStartCol = headerRow?._cells.find(cell => cell.value === contributionBuckets[0])?.col;
    sheet.mergeCells(YEAR_HEADER_ROW_INDEX, latestYearBucketStartCol, YEAR_HEADER_ROW_INDEX, latestYearBucketStartCol + contributionBuckets.length - 1);

    // Add year header values to the row (other than the latest year)
    const yearRow = sheet.getRow(YEAR_HEADER_ROW_INDEX);
    const rowsBeforeBuckets = infoHeaders.length;
    for (let i = 1; i < orderedUniqueYears.length; i++) {
        yearRow.getCell(i + contributionBuckets.length + rowsBeforeBuckets).value = orderedUniqueYears[i];
        yearRow.getCell(i + contributionBuckets.length + rowsBeforeBuckets).font = { bold: true };
    }
    if (report.details.length > 0) {
        const data = report.details.map(detail => {
            //map contributionDetails to proper year
            // Current year should show all buckets and prior years only the sum
            const contributionDetails = orderedUniqueYears.map((year, index) => {
                const detailData = detail.contributionDetails.find(contribution => Number(contribution.year) === year);
                let contribution = TerminationReportBusiness.handleLatestYearVSOtherYears({
                    Regular: detailData?.regTotal,
                    Maternity: detailData?.matTotal,
                    'Long Term': detailData?.ltdTotal,
                    Self: detailData?.slfTotal,
                    Voluntary: detailData?.volTotal,
                    Retro: detailData?.retroTotal,
                }, { contributionSum: detailData?.contributionTotal, adjOrYESum: detailData?.adjustmentTotal}, index === 0);

                return contribution;
            }).flat();

            const row = { row: [detail.sin, detail.name, detail.code, ...contributionDetails] };
            return row;
        })
        excel.addCustomDataRows(sheet, data)
    }

    // BORDERS
    // Get the row index of the header row (aka number of rows before the data starts)
    const headerRowIndex = headerRow?.number;

    // Get the total number of rows in the sheet
    const totalRows = report.details.length + headerRowIndex;

    // Get last column index for the latest year (minus one because latstYearBucket includes first column)
    const latestYearLastColumn = rowsBeforeBuckets + contributionBuckets.length;

    // Add borders for the latest year
    excel.createBorder(sheet, {row: YEAR_HEADER_ROW_INDEX, col: latestYearBucketStartCol}, {row: totalRows, col: latestYearLastColumn});

    // Add borders for the other years
    orderedUniqueYears.slice(1).forEach((_, index) => {
        const yearIndex = index + 1;
        excel.createBorder(sheet, {row: 1, col: yearIndex + contributionBuckets.length + rowsBeforeBuckets}, {row: totalRows, col: latestYearBucketStartCol + contributionBuckets.length + index});
    })
}

function loadSummary (report, sheet, template) {
    let groupHeaders = new Excel.Headers(Report, [
        'employmentFull',
        'details', 
        'pensionableEarnings', 
        'contributions', 
        'creditedService', 
        'pensionAdjustment', 
        'lastYearDetails', 
        'adjustments', 
        'validation',
        'REG',
        'OVR',
        'OTE'
    ])

    groupHeaders = YearEndService.getHeader(YearEndService.displayTypes.SIMPLE, Excel, report.earningTypes, groupHeaders);

    const valueHeaders = groupHeaders.list.reduce((headers, groupHeader) => {
        if(groupHeader.headers) headers = headers.concat(groupHeader.headers.list)
        return headers
    }, [])

    template.addDataRows(sheet, valueHeaders, report.details, 4)

    const earningTypesHeadersStart = 47 //AR
    const summaryHeaderRow = sheet.getRow(1)
    const regCell = summaryHeaderRow.getCell(earningTypesHeadersStart)
    const ovrCell = summaryHeaderRow.getCell(earningTypesHeadersStart + groupHeaders['REG'].headers.list.length)
    const oteCell = summaryHeaderRow.getCell(ovrCell.col + groupHeaders['OVR'].headers.list.length )
    regCell.value = 'Regular Earnings by Pay Code'
    ovrCell.value = 'Overtime Earnings by Pay Code'
    oteCell.value = 'One-Time Earnings by Pay Code'
    ovrCell.style = regCell.style
    oteCell.style = regCell.style
    sheet.mergeCells(1, regCell.col, 1, ovrCell.col - 1 )
    sheet.mergeCells(1, ovrCell.col, 1, oteCell.col - 1 )
    sheet.mergeCells(1, oteCell.col, 1, oteCell.col + groupHeaders['OTE'].headers.list.length - 1)

    const summarySubHeaderRow = sheet.getRow(2)
    valueHeaders.slice(earningTypesHeadersStart - 1).forEach((header, index) => {
        summarySubHeaderRow.getCell(earningTypesHeadersStart + index).value = header.title
        summarySubHeaderRow.getCell(earningTypesHeadersStart + index).style = summarySubHeaderRow.getCell(earningTypesHeadersStart).style
    })
}

export { YEAR_END_TABS, YEAR_END_CONFIG };