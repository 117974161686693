import React, { useEffect, useState } from "react"
import { Card, Modal, Row } from '../../framework/containers'
import { Button, Table } from '../../framework/controls'
import { useHistory, useParams } from "react-router-dom"

import useEmployer from "../../hooks/useEmployer"
import Loading from "../../components/containers/Loading"
import NewEmploymentForm from "./NewEmploymentForm"

import EmploymentSearch from '../../services/search/EmploymentSearch'
import EmploymentView from "../../entities/search/EmploymentSearch/EmploymentView"
import FilterContainer from "../../components/containers/FilterContainer"
import { isObject } from "lodash"
import EmployeeIdImport from "./EmployeeIdImport"

const searchAll = async (employerId, currentFilters) => {
    return EmploymentSearch.scrollSearch({employerId, ...currentFilters});
}

const EmploymentsPage = () => {
    const { employerCode } = useParams();
    const { employerId, employer } = useEmployer();
    const [paginationInfo, setPaginationInfo] = useState({});
    const [employments, setEmployments] = useState();
    const [newEmploymentFlow, setNewEmploymentFlow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentFilters, setCurrentFilters] = useState();

    const columns = new Table.Headers(
        EmploymentView, 'sin, nameOriginal, employmentEventDescription, employeeNumber, joinDateString, participationEventDescription, baseMonthlyEarning' 
    );

    columns['employeeNumber'].hideIfEmpty = true;

    useEffect(() => {
        if (isObject(currentFilters) && employerId) {
            loadEmployments();
        } 
    }, [currentFilters, employerId]);

    let history = useHistory();

    const handleSelect = (row) => { 
        history.push(`/employer/${employerCode}/employment/${row.id}`);
    }
    
    const loadEmployments = (pageNumber = 1, scrollDuration, scrollId) => {
        search({pageNumber, scrollDuration, scrollId}).then((results) => {
            setEmployments(results.documents.all);
            setPaginationInfo({total: results.total, totalPages: results.totalPages, currentPage: pageNumber, pageSize: results.pageSize});
            setIsLoading(false);
        });
    }

    const search = async ({pageNumber = 1, scrollDuration, scrollId}) => {
        let pageSize = EmploymentSearch.DEFAUT_PAGE_SIZE;
        if (currentFilters.pageSize) pageSize = parseInt(currentFilters.pageSize);

        return EmploymentSearch.search({employerId, ...currentFilters}, pageNumber, pageSize, scrollDuration, scrollId);
    }

    const onPageChange = (selectedPageNumber) => loadEmployments(selectedPageNumber);

    return (
        <>
        	<Row className='mb-2 justify-content-between'>
                <FilterContainer view={EmploymentView} onUpdate={setCurrentFilters}
                    exportFn={async () => searchAll(employerId, currentFilters)} filterBoxWidth={300}/>
                <div className="d-flex g5">
                    <Button
                        key="new-employment"
                        className="btn btn-secondary no-mar"
                        onClick={() => setNewEmploymentFlow(true)}
                    >
                        New Employment
                    </Button>
                    {newEmploymentFlow && <Modal id='personEdit' className='h-100 w-60 modal-bg-color'>
                        <NewEmploymentForm 
                            onCancel={() => setNewEmploymentFlow(false)} 
                            employer={employer}
                        />
                    </Modal>}
                    <EmployeeIdImport onClose={loadEmployments} /> 
                </div>
		    </Row>
            {!isLoading ? (
                <div className="h-100 d-flex flex-column justify-content-between mt-2">
                    <div className="h-90">
                        <Table
                            id="employments-table"
                            data={employments}
                            columns={columns}
                            onSelect={handleSelect}
                            sort="person.name"
                            className="h-90"
                            isPaginated
                            paginationInfo={{onPageChange, ...paginationInfo }}
                        />
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default EmploymentsPage;