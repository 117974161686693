/**
 * Definitions: 
 * 
 *  - group: the validation column the message belongs in
 *           (see Validation.js for the list of groups)
 */

export const yeMessageEventConfig = {
    default: {},
    missingPersonalInformation: {
        group: "persInfo",
    },
    stsActNoEarnings: {
        group: "sts",
    },
    stsLeaveAllYearAndEarnings: {
        group: "sts",
    },
    missingEmploymentType: {
        group: "sts",
    },
    stdMatAndNoMatContribs: {
        group: "sts",
    },
    stdJoinDtAfterHireDt: {
        group: "sts",
    },
    stdTermitatedPrevYear: {
        group: "sts",
    },
    stdTermitatedPrevYearHasPA: {
        group: "sts",
    },
    janOne: {
        group: "sts",
    },
    guessedDate: {
        group: "sts",
    },
    casualType: {
        group: "sts",
    },
    monthlyEarnings: {
        group: "monthlyEarnings",
    },
    creSrv: {
        group: "creSrv",
    },
    deemedEarnHoursValidation: {
        group: "deeContrib",
    },
    reqValidation: {
        group: "reqContrib",
    },
    deemedPrior: {
        group: "reqContrib",
    },
    negEarnings: {
        group: "reqContrib",
    },
    merEarnings: {
        group: "reqContrib",
    },
    maxCon: {
        group: "maxContrib",
    },
    maxVol: {
        group: "maxContrib",
    },
    maxBoth: {
        group: "maxContrib",
    },
    highEarn: {
        group: "annErn",
    },
    lowEarn: {
        group: "annErn",
    },
    incomplete: {
        group: "result",
    },
    ok: {
        group: "result",
    },
};
