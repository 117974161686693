import { Service } from '../../framework'
import { BeneficiaryService, EmployerService, PersonService } from '..';
import PersonalInformationResults from '../../entities/search/PersonalInformationSearch/PersonalInformationResults';

/**
 * @extends {Service}
 */
class PersonalInformationSearch extends Service {
    constructor() {
        super(PersonalInformationResults, 'Employments', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    /**
     * 
     * @param {object} parameters 
     * @param {number | undefined} page Default: 1
     * @param {number | undefined} pageSize Default: 15
     * @param {string} scrollDuration 
     * @param {string | undefined} scrollId 
     * @returns {Promise<PersonalInformationResults>}
     */
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }

    /**
     * Recursively search until all results are returned
     * @param {object} searchParams
     * @returns {Promise<PersonalInformationResults>}
     */
    async scrollSearch(searchParams) {
      const result = await this._scrollSearch(searchParams);
      return result;
    }

    async load(eventResults, options) {
        const documents = eventResults.documents.all || [];
        for (const document of documents) {
            document.employer = await EmployerService.get(document.employerId);;
            document.person = await PersonService.get(document.personId);
            document.beneficiaries = await BeneficiaryService.get(document.personId);
        }
        return eventResults
    }
}

const instance = new PersonalInformationSearch()
export default instance

