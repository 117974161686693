import SearchResult from '../SearchResult'

/**
 * @extends {SearchResult}
 */
export default class PersonResults extends SearchResult {
    /** @type {PersonView[]} */
    documents;
    static definitions = {
        documents: { map: true, isListRef: true, ref: require('./PersonView'), text: 'Documents' },
    }
}