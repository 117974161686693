import {
  sort,
  round,
  add,
  moment,
  sum,
} from "../../../../framework/utils/helper";
import { chunk, sortBy, groupBy, merge as _merge } from "lodash";
import RemittancesLogReport from "./RemittancesLogReport";
import AuditSummaryView from "./views/AuditSummaryView";
import { defaultExportNumberConfig } from "./util/ReportsUtil";

/**
 * Summary report of the Processing Log report
 */
export default class AuditSummaryReport extends RemittancesLogReport {
  reportDesc = "Summary Report for Audit";
  /** Excel file name */
  get reportExportLabel() {
    const jurFilter =
      this.state.filtersInstance?.["employer.plan.jurisdiction"];
    const jurFilterLabel = jurFilter ? `-${jurFilter}` : "";
    const employerFilter = this.state.filtersInstance?.["employer"];
    const employerFilterLabel = employerFilter?.code
      ? `-${employerFilter.code}`
      : "";
    const fromParamLabel = this.state.queryParamsInstance?.from?.value || "";
    const toParamLabel = this.state.queryParamsInstance?.to?.value || "";
    return `AuditSummary${jurFilterLabel}${employerFilterLabel}-${fromParamLabel}-${toParamLabel}`;
  }
  entity = AuditSummaryView;

  headers = [
    // 'fromPeriod', 'toPeriod',
    "employer.code",
    "employer.plan.jurisdiction",
    "regularSum",
    "maternitySum",
    "longTermSum",
    "selfSum",
    "eeAdjustmentsSum",
    "totalEeContribsSum",
    "erContribsSum",
    "totalErAdjustmentsNoCreditSum",
    "totalEmployerErContributionsSum",
    "voluntarySum",
    "totalCurrentServiceContribsSum",
    "solvencyDeficitSum",
    "interestSum",
    "creditUsedNegativeSum",
    "prevTotalOwingFirstPeriod",
    "totalPaymentsNegativeSum",
    "totalOwingLastPeriod",
    "totalRemainingBalanceLastPeriod",
    "balanceLastPeriod",
  ];
  headerProps = {
    // See also headerProps in RemittancesLogReport
    "employer.code": { minWidth: 78 },
    "employer.plan.jurisdiction": { minWidth: 84 },
    regularSum: { overrideText: "A\nDeductions [EE]" },
    maternitySum: { overrideText: "B\nMaternity [EE]" },
    longTermSum: { overrideText: "C\nLong-Term [EE]" },
    selfSum: { overrideText: "D\nSelf [EE]" },
    eeAdjustmentsSum: { overrideText: "E\nTotal EE Adjustments" },
    totalEeContribsSum: {
      overrideText: "F\n{A+B+C+D+E}\nTotal Employee [EE] Contributions",
    },
    erContribsSum: { overrideText: "G\nEmployer Contributions" },
    totalErAdjustmentsNoCreditSum: { overrideText: "H\nTotal ER Adjustments" },
    totalEmployerErContributionsSum: {
      overrideText: "J\n{G+H}\nTotal Employer [ER] Contributions",
    },
    voluntarySum: { overrideText: "K\nVoluntary" },
    totalCurrentServiceContribsSum: {
      overrideText: "L\n{F+J+K}\nTotal Current Service Contributions",
    },
    solvencyDeficitSum: { overrideText: "M\nTotal Solvency Contributions" },
    interestSum: { overrideText: "N\nTotal Interest on Late Payment" },
    creditUsedNegativeSum: {
      overrideText: "O\nER Holiday Offset\n{J + N not owing}",
    },
    prevTotalOwingFirstPeriod: { overrideText: "P\nPrevious Balance" },
    totalPaymentsNegativeSum: { overrideText: "Q\nTotal Payments" },
    totalOwingLastPeriod: {
      overrideText: "R\n{L+M+N+O+P+Q}\nTotal Owing",
    },
    totalRemainingBalanceLastPeriod: {
      overrideText:
        "S\nCarrying Balance of Holiday Credit to Offset ER Portions",
    },
    balanceLastPeriod: { overrideText: "T\n{R+S}\nBalance" },
  };

  /**
   * Config for the tabs (to render the tables and to export the data)
   *
   * For each tab config:
   * - tabName: name/key of the tab. It will be the suffix of the exported file name.
   * - tabTitle: title of the tab.
   * - tableHeaders: headers of the table (columns in Tabulator).
   * - tableHeadersProps: props for the table headers.
   * - exportColumns: list of columns to export. Includes the config of each exported column. Note: an export column should not be a group header.
   */
  tabsConfig = [
    {
      tabName: "Summary",
      tabTitle: "Summary",
      tableHeaders: [...this.headers],
      tableHeadersProps: { ...this.headerProps },
      exportColumns: _merge(
        { ...this.headerProps },
        {
          regularSum: { ...defaultExportNumberConfig },
          maternitySum: { ...defaultExportNumberConfig },
          longTermSum: { ...defaultExportNumberConfig },
          selfSum: { ...defaultExportNumberConfig },
          eeAdjustmentsSum: { ...defaultExportNumberConfig },
          totalEeContribsSum: { ...defaultExportNumberConfig },
          erContribsSum: { ...defaultExportNumberConfig },
          totalErAdjustmentsNoCreditSum: { ...defaultExportNumberConfig },
          totalEmployerErContributionsSum: { ...defaultExportNumberConfig },
          voluntarySum: { ...defaultExportNumberConfig },
          totalCurrentServiceContribsSum: { ...defaultExportNumberConfig },
          solvencyDeficitSum: { ...defaultExportNumberConfig },
          interestSum: { ...defaultExportNumberConfig },
          creditUsedNegativeSum: { ...defaultExportNumberConfig },
          prevTotalOwingFirstPeriod: { ...defaultExportNumberConfig },
          totalOwingPrePaymentLastPeriod: { ...defaultExportNumberConfig },
          totalPaymentsNegativeSum: { ...defaultExportNumberConfig },
          totalOwingLastPeriod: { ...defaultExportNumberConfig },
          totalRemainingBalanceLastPeriod: { ...defaultExportNumberConfig },
          balanceLastPeriod: { ...defaultExportNumberConfig },
        }
      ),
    },
  ];

  sheetsConfig = {
    Summary: {
      sheetName: "Summary",
      headers: [...this.headers],
      headerProps: { ...this.headerProps },
    },
  };

  /**
   *
   * @param {Remittance[]} remittances
   * @returns {AuditSummaryView[]}
   */
  #summarizeRemittances(remittances) {
    const groupedRemittances = groupBy(remittances, "employer.code");
    const summarizedRemittances = Object.keys(groupedRemittances).map(
      (employerCode) => {
        const employerRemittances = sortBy(groupedRemittances[employerCode], [
          "period.intValue",
        ]);
        const groupedRemittance = new AuditSummaryView({
          employer: employerRemittances[0]?.employer?.id,
          regularSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.regular)
              .reduce((total, regular) => add(regular, total), 0)
          ), // sum
          maternitySum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.maternity)
              .reduce((total, maternity) => add(maternity, total), 0)
          ), // sum
          longTermSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.longTerm)
              .reduce((total, longTerm) => add(longTerm, total), 0)
          ), // sum
          selfSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.self)
              .reduce((total, self) => add(self, total), 0)
          ), // sum
          eeAdjustmentsSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.eeAdjustments)
              .reduce((total, eeAdjustments) => add(eeAdjustments, total), 0)
          ), // sum
          totalEeContribsSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.totalEeContribs)
              .reduce(
                (total, totalEeContribs) => add(totalEeContribs, total),
                0
              )
          ), // sum
          erContribsSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.erContribs)
              .reduce((total, erContribs) => add(erContribs, total), 0)
          ), // sum
          totalErAdjustmentsNoCreditSum: round(
            employerRemittances
              .map(
                (employerRemittance) =>
                  employerRemittance.totalErAdjustmentsNoCredit
              )
              .reduce(
                (total, totalErAdjustmentsNoCredit) =>
                  add(totalErAdjustmentsNoCredit, total),
                0
              )
          ), // sum
          totalEmployerErContributionsSum: round(
            employerRemittances
              .map(
                (employerRemittance) =>
                  employerRemittance.totalEmployerErContributions
              )
              .reduce(
                (total, totalEmployerErContributions) =>
                  add(totalEmployerErContributions, total),
                0
              )
          ), // sum
          voluntarySum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.voluntary)
              .reduce((total, voluntary) => add(voluntary, total), 0)
          ), // sum
          totalCurrentServiceContribsSum: round(
            employerRemittances
              .map(
                (employerRemittance) =>
                  employerRemittance.totalCurrentServiceContribs
              )
              .reduce(
                (total, totalCurrentServiceContribs) =>
                  add(totalCurrentServiceContribs, total),
                0
              )
          ), // sum
          solvencyDeficitSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.solvencyDeficit)
              .reduce(
                (total, solvencyDeficit) => add(solvencyDeficit, total),
                0
              )
          ), // sum
          interestSum: round(
            employerRemittances
              .map((employerRemittance) => employerRemittance.interestSum)
              .reduce((total, interestSum) => add(interestSum, total), 0)
          ), // sum
          creditUsedNegativeSum: round(
            employerRemittances
              .map(
                (employerRemittance) => employerRemittance.creditUsedNegative
              )
              .reduce(
                (total, creditUsedNegative) => add(creditUsedNegative, total),
                0
              )
          ), // sum
          prevTotalOwingFirstPeriod: employerRemittances[0].prevTotalOwing, // first
          totalOwingPrePaymentLastPeriod:
            employerRemittances[employerRemittances.length - 1]
              .totalOwingPrePaymentSum, // last
          totalPaymentsNegativeSum: round(
            employerRemittances
              .map(
                (employerRemittance) => employerRemittance.totalPaymentsNegative
              )
              .reduce(
                (total, totalPaymentsNegative) =>
                  add(totalPaymentsNegative, total),
                0
              )
          ), // sum
          totalOwingLastPeriod:
            employerRemittances[employerRemittances.length - 1].totalOwingSum, // last
          totalRemainingBalanceLastPeriod:
            employerRemittances[employerRemittances.length - 1]
              .totalRemainingBalance, // last
          balanceLastPeriod:
            employerRemittances[employerRemittances.length - 1].balance, // last
        });
        if (!groupedRemittance.employer?.code) {
          groupedRemittance.employer = employerRemittances[0]?.employer;
        }
        return groupedRemittance;
      }
    );
    return summarizedRemittances;
  }

  /**
   * @override
   * @param {{to: Period; from: Period}} queryParams
   * @returns {Promise<AuditSummaryView[]>}
   */
  async execQuery(queryParams) {
    const remittances = await super.execQuery(queryParams);
    const summarizedRemittances = this.#summarizeRemittances(remittances);

    // the ReportLayoutTabs components needs the tabs data in the state
    // (the tabs data can't be the return value of execQuery because the ReportLayout component needs execQuery to return an array of items)
    this.setState({
      tabsData: {
        // No need to use summarizedRemittances_asObjects = formatTabulatorSafeDataInstance(summarizedRemittances)
        // because summarizedRemittances is of type AuditSummaryView[] and AuditSummaryView has no getters
        Summary: summarizedRemittances,
      },
    });

    return summarizedRemittances;
  }
}
