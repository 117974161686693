import { useState } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";
import DoubleColumnDisplay from "../../components/containers/DoubleColumnDisplay";
import { Spouse } from "../../entities";
import { Question } from "../../framework/components";
import { FieldSet, Modal, Row } from "../../framework/containers";
import { Icon, Table } from "../../framework/controls";
import { renderComponent } from "../../framework/utils/renders";
import { PersonService, SpouseService } from "../../services";
import SpouseForm from "./SpouseForm";

const DELETE_CONFIRMATION = 'Are you sure you want to remove this spouse from the history?';
const JURISDICTION = {
    que: {
        label: 'Quebec',
        tooltip: <div>
            Quebec (Supplemental Pension Plans Act): <br/>
            Your spouse is the person who, at the date a determination of spousal status is required: <br/>
            <ol type='a'>
                <li>is married to you; or</li>
                <li>if you are not married to anyone, a person of the opposite sex or same sex who has been 
                    living in a conjugal relationship with you for a period of not less than three years, or 
                    for a period of not less than one year if:
                    <ol type='i'>
                        <li>at least one child is born, or to be born, of your union;</li>
                        <li>you have jointly adopted at least one child while living together in a conjugal relationship; or</li>
                        <li>one of you has adopted at least one child of the other while living together in a conjugal relationship.</li>
                    </ol> 
                </li>
            </ol>
        </div>
    },
    fed: {
        label: 'Federal',
        tooltip: <div>
            Federal (Pension Benefits Standards Act): <br/>
            Your spouse is the person who, at the date a determination of spousal status is required: <br/>
            <ol type='a'>
                <li>if there is no person described in (b) below, is married to you or is party to a void marriage with you; or</li>
                <li>has been cohabiting with you in a conjugal relationship for at least one year.</li>
            </ol>
    </div>
    },
}

const SpouseJurisdiction = ({spouse, jurisdiction, onEdit}) => {

    const title = <>{`Legal Spouse (${jurisdiction.label})` } <Icon 
                tooltip={jurisdiction.tooltip} 
                icon='question-circle' 
                className='text-primary'
                tooltip-right 
                small/></>

    return <FieldSet title={title} className='mt-2' variant='field'>
        <Row>
            <div style={{flex: 4}} >
                <DoubleColumnDisplay entity={Spouse} instance={spouse} properties={[
                    'person.sin',
                    'nameFirstLast',
                    'dob',
                ]} />
            </div>
            <div style={{flex: 1}} className={''} >
                <Button style={{margin: 0}} className='btn-secondary btn-icon float-right' onClick={() => onEdit(spouse)}>
                    <Icon icon={"pencil-alt"} />
                </Button> 
            </div>
        </Row>
        <div className="mt-2">
            <Row className="bold">Comments:</Row>
            <div className='simple-card'>
                {(spouse?.cmt && spouse?.cmt !== '') ? spouse.cmt : <span className="btn-ghost">No comments.</span>}
            </div>
        </div>
    </FieldSet>
}

const SpouseDisplay = ({membership, onClose, onRefreshMember}) => {
    const columns = new Table.Headers(Spouse, 'effDt, nameFirstLast, maritalStatus, jurisdiction, maritalBreakDownOption, cmt',);
    
    const [question, setQuestion] = useState();
    const [selectedSpouse, setSelectedSpouse] = useState();
    const [isLoading, setLoading] = useState(false);
    
    /**
     * 
     * @param {boolean} answer 
     * @param {string} id 
     */
    const handleDelete = async (answer, id) => {
        if(answer) {
            setLoading(true);
            membership.person.spouseHistory.removeById(id);
            return SpouseService.delete(membership.person.id, id).then(() => {
                finalizeSave();
                setQuestion();
            });
        } else {
            setQuestion();
        }
    }

    const handleDeleteQuestion = (e, cell) => {
        e.preventDefault();
        e.stopPropagation();
        const spouse = cell.getRow().getData();
        
        setQuestion(<Question title={DELETE_CONFIRMATION} onAnswer={(answer) => handleDelete(answer, spouse.id)} />);
    }

    /**
     * 
     * @param {Spouse} spouse 
     * @returns {Promise<void>}
     */
    const handleEdit = async (spouse) => {
        setLoading(true);
        membership.person.spouseHistory.removeById(spouse.id);
        membership.person.spouseHistory.push(spouse);
        return SpouseService.update(spouse).then((result) => {
            finalizeSave();
        });
    }

    /**
     * 
     * @param {Spouse} spouse 
     * @returns {Promise<void>}
     */
    const handleAdd = async (spouse) => {
        setLoading(true);
        membership.person.spouseHistory.push(spouse);
        return SpouseService.add(spouse).then((result) => {
            finalizeSave();
        });
    }

    /**
     * 
     * @param {Spouse} spouse 
     */
    const onEdit = (spouse) => setSelectedSpouse(spouse);
    const finalizeSave = async () => {
        setLoading(false);
        onRefreshMember?.();
    }

    columns['delete'] = { 
        width: 50, 
        align:"center", 
        cellClick: handleDeleteQuestion, 
        headerClick: () => { 
            const spouse = new Spouse();
            spouse.parent = membership.person.id;
            setSelectedSpouse(spouse) 
        }, 
        format: () => renderComponent(<Icon icon='minus-circle' large/>),  
        headerSort: false, 
        titleFormatter: () => renderComponent(<Icon icon='plus-circle' large className="v-bottom"/>)
    };

    const renderMBFlag = (value, instance) => {
        const defs = Spouse.definitions.maritalBreakDownOption;
        const option = (defs.options.find(x=>x.key === instance.maritalBreakDownOption));
        if (!option?.flag) return;

		let icon = renderComponent(<Icon 
				icon='flag'
				tooltip='Test'
				tooltip-right
                style={{color: option.flag.color + ' !important'}}
			/>)
		
		return icon;
	}

    const renderSingle = (value, instance) => {
		return instance.isSingle ? renderComponent( <span className="btn-ghost"> No Spouse </span>) : value;
	}

    columns['effDt'].title = 'Designation Date';
    columns['nameFirstLast'].format = renderSingle;
    columns['maritalBreakDownOption'].title = 'MB Flag';
    columns['maritalBreakDownOption'].format = renderMBFlag;
    columns['maritalBreakDownOption'].maxWidth = 100;

    const employers = membership.participations.reduce((employersByPP, participation) => {
        employersByPP.push(participation.employments.all.map(x=>x.employer));
        return employersByPP;
    }, []).flat()

    return isLoading ? <Loader/> : <div>
                <div className={'transfer-container'}>
                <div style={{flex: 1, minWidth: '25%', maxWidth: '30%'}} >
                    <SpouseJurisdiction onEdit={onEdit} jurisdiction={JURISDICTION.fed} spouse={membership.person.spouseFed}/>
                    <SpouseJurisdiction onEdit={onEdit} jurisdiction={JURISDICTION.que} spouse={membership.person.spouseQue} />
                </div>
                <div style={{flex: 2, overflowX: 'scroll'}} className="mt20">
                    <div className="w-100 h-100">
                        <Table 
                            className='h-100'
                            data={membership.person.spouseHistory.sort()} 
                            columns={columns}
                            id="historical-table" 
                            onSelect={onEdit}  
                            fitHeightToContent 
                        />            
                    </div>
                </div>
                {selectedSpouse && <Modal className='w-60 modal-bg-color'>
                    <SpouseForm spouse={selectedSpouse} employers={employers} onClose={() => setSelectedSpouse()} onSave={handleEdit} onAdd={handleAdd} spouseHistory={membership.person?.spouseHistory?.clone()}/>    
                </Modal>}
                { question }
       
            </div>
        </div>
}

export default SpouseDisplay;