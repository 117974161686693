import { Ref, Definition } from "../../../../../framework/infra";

/**
 * @extends {Ref}
 */
export default class AuditSummaryView extends Ref {
  /**
   * Sum of `regular` of all selected remittances
   * @type {number} */
  regularSum;
  /**
   * Sum of `maternity` of all selected remittances
   * @type {number} */
  maternitySum;
  /**
   * Sum of `longTerm` of all selected remittances
   * @type {number} */
  longTermSum;
  /**
   * Sum of `self` of all selected remittances
   * @type {number} */
  selfSum;
  /**
   * Sum of `voluntary` of all selected remittances
   * @type {number} */
  voluntarySum;
  /**
   * Sum of `eeAdjustments` of all selected remittances
   * @type {number} */
  eeAdjustmentsSum;
  /**
   * Sum of `totalEeContribs` of all selected remittances
   * @type {number} */
  totalEeContribsSum;
  /**
   * Sum of `erContribs` of all selected remittances
   * @type {number} */
  erContribsSum;
  /**
   * Sum of `totalErAdjustmentsNoCredit` of all selected remittances
   * @type {number} */
  totalErAdjustmentsNoCreditSum;
  /**
   * Sum of `totalEmployerErContributions` of all selected remittances
   * @type {number} */
  totalEmployerErContributionsSum;
  /**
   * Sum of `totalCurrentServiceContribs` of all selected remittances
   * @type {number} */
  totalCurrentServiceContribsSum;
  /**
   * Sum of `solvencyDeficit` of all selected remittances
   * @type {number} */
  solvencyDeficitSum;
  /**
   * Sum of `interest` of all selected remittances
   * @type {number} */
  interestSum;
  /**
   * Sum of `creditUsedNegative` of all selected remittances
   * @type {number} */
  creditUsedNegativeSum;
  /**
   * `prevTotalOwing` of the first selected remittance (first period)
   * @type {number} */
  prevTotalOwingFirstPeriod;
  /**
   * `totalOwingPrePayment` of the last selected remittance (last period)
   * @type {number} */
  totalOwingPrePaymentLastPeriod;
  /**
   * Sum of `totalPaymentsNegative` of all selected remittances
   * @type {number} */
  totalPaymentsNegativeSum;
  /**
   * `totalOwing` of the last selected remittance (last period)
   * @type {number} */
  totalOwingLastPeriod;
  /**
   * `totalRemainingBalance` of the last selected remittance (last period)
   * @type {number} */
  totalRemainingBalanceLastPeriod;
  /**
   * `balance` of the last selected remittance (last period)
   * @type {number} */
  balanceLastPeriod;

  /**
   * @type {Employer}
   */
  employer;
  /**
   * @type {Period}
   */
  fromPeriod;
  /**
   * @type {Period}
   */
  toPeriod;

  static definitions = {
    employer: {
      ref: require("../../../../../entities/employment/Employer"),
      text: "Employer",
    },
    fromPeriod: { type: Definition.types.PERIOD, text: "Period" },
    toPeriod: { type: Definition.types.PERIOD, text: "Period" },

    prevTotalOwingFirstPeriod: {
      type: Definition.types.AMOUNT,
      text: "Previous Total Owing",
    },
    erContribsSum: {
      type: Definition.types.AMOUNT,
      text: "Employer Contributions",
    },
    creditUsedNegativeSum: {
      type: Definition.types.AMOUNT,
      text: "Credit Used Negative",
    },
    regularSum: {
      type: Definition.types.AMOUNT,
      text: "Regular",
    },
    maternitySum: { type: Definition.types.AMOUNT, text: "Maternity" },
    longTermSum: { type: Definition.types.AMOUNT, text: "Long Term" },
    selfSum: { type: Definition.types.AMOUNT, text: "Self" },
    voluntarySum: { type: Definition.types.AMOUNT, text: "Voluntary" },
    totalEeContribsSum: {
      type: Definition.types.AMOUNT,
      text: "Total Employee Contributions",
    },
    eeAdjustmentsSum: {
      type: Definition.types.AMOUNT,
      text: "Employee Adjustments",
    },
    totalCurrentServiceContribsSum: {
      type: Definition.types.AMOUNT,
      text: "Total Current Service Contributions",
    },
    solvencyDeficitSum: {
      abstract: true,
      type: Definition.types.AMOUNT,
      text: "Solvency Deficit",
    },
    totalErAdjustmentsNoCreditSum: {
      type: Definition.types.AMOUNT,
      text: "Total ER Adjustments",
    },
    totalEmployerErContributionsSum: {
      type: Definition.types.AMOUNT,
      text: "Total Employer [ER] Contributions",
    },
    totalRemainingBalanceLastPeriod: {
      type: Definition.types.AMOUNT,
      text: "Total Remaining Balance",
    },
    totalOwingPrePaymentLastPeriod: {
      type: Definition.types.AMOUNT,
      text: "Total Owing (pre-payment)",
    },
    totalOwingLastPeriod: {
      type: Definition.types.AMOUNT,
      text: "Total Owing",
    },
    interestSum: {
      type: Definition.types.AMOUNT,
      text: "Total Interest on Late Payment",
    },
    totalPaymentsNegativeSum: {
      type: Definition.types.AMOUNT,
      text: "Total Payments Negative",
    },
    balanceLastPeriod: { type: Definition.types.AMOUNT, text: "Balance" },
  };

  static key = ["employer", "fromPeriod"];
}
