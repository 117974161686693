import { Definition } from '../../../framework/infra'
import { moment } from '../../../framework/utils/helper'
import { MembershipService } from '../../../services'
import { Membership, Spouse } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import { Excel } from '../../../framework/utils'

export default class NewMembersReport extends ReportLayout {
    reportDesc = 'New Members'
    entity = Membership
    headers = [
        'person.sin', 'lastEnrolledParticipation.mercerKey', 'person.firstName', 'person.lastName',
        'lastEnrolledParticipation.employments.last.employer.code', 'lastEnrolledParticipation.employments.last.employer.mercerDiv', 
        'person.gender', 'person.dob', 'person.lng', 'lastEnrolledParticipation.employments.last.isN', 
        'lastEnrolledParticipation.employments.last.isCQ', 'lastEnrolledParticipation.employments.last.isTP', 
        'lastEnrolledParticipation.employments.last.hiredDate', 'lastEnrolledParticipation.joinDt', 'lastEnrolledParticipation.statusDesc', 
        'lastEnrolledParticipation.lastStatusEvent.effDt', 'person.address.desc', 
        'lastEnrolledParticipation.employments.last.spouse.nameFirstLast', 'lastEnrolledParticipation.employments.last.spouse.dob', 
        'lastEnrolledParticipation.employments.last.spouse.taxStatus', 'lastEnrolledParticipation.beneficiaries.desc'
    ]
    headerProps = {
        'lastEnrolledParticipation.employments.last.spouse.nameFirstLast': {title: 'Spouse Name'}, 
        'lastEnrolledParticipation.employments.last.spouse.dob': {title: 'Spouse DOB'}, 
        'lastEnrolledParticipation.employments.last.spouse.taxStatus': {title: 'Spouse Tax Status'},
        'lastEnrolledParticipation.statusDesc': {title: 'Participation Status'},
        'lastEnrolledParticipation.lastStatusEvent.effDt': {title: 'Effective Date'},
        'lastEnrolledParticipation.beneficiaries.desc': {title: 'Beneficiaries'},
        'lastEnrolledParticipation.employments.last.employer.code': {width: 100},
        'person.sin': {width: 130},
        'person.dob': {width: 120},
        'person.firstName': {width: 120},
        'person.lastName': {width: 120},
    }
    tableSort = 'person.name'
    filters = {
        'lastEnrolledParticipation.employments.last.employer': {sortBy: 'code', display: 'longDesc', nullable: true}
    }
    params = {
        from: {definition: {type: Definition.types.DATE, text: "From", default: moment().startOf('year').format('YYYY-MM-DD')}},
        to: {definition: { type: Definition.types.DATE, text: "To", default: moment().format('YYYY-MM-DD')}}
    }

    /**
     * Handles custom export columns for beneficiaries
     * @param {Excel} excel 
     * @param {Membership[]} data 
     */
    customExportColumns = (excel, data) => {
        const ws = excel.workBook.worksheets.find(sheet => sheet.name === 'New Members');
        const beneficiariesLength = data.reduce((max, member) => Math.max(max, member.lastEnrolledParticipation.beneficiaries.latestGroup.length), 0);
        const headersRow = ws._rows.find(row => row.values[1] === 'SIN');
        const beneficiaryStartCol = ws.columns.find(col => col.values[1] === 'Beneficiaries')?.number;

        // Add beneficiary headers
        for (let i = 0; i < beneficiariesLength; i++) {
            ws.getCell(headersRow.number, beneficiaryStartCol + i).value = `Beneficiary ${i + 1}`;
            ws.getCell(headersRow.number, beneficiaryStartCol + i).font = { bold: true };
        }

        // Add beneficiary data into separate columns for each member
        data.forEach((member) => {
            const row = ws._rows.find(r => r.values[1] === member.person.formatedSin);
            member.lastEnrolledParticipation.beneficiaries.latestGroup.forEach((beneficiary, i) => {
                ws.getCell(row.number, beneficiaryStartCol + i).value = beneficiary.details;
            })
        })
    }
    
    execQuery(queryParams) {
        return MembershipService.getNewMembersReport(queryParams.from, queryParams.to).then(members => members._list);
    }
}