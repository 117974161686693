import { EmploymentService, ParticipationService } from "../../services";
import moment from "moment";
    import { EVENT_SOURCE } from "../../framework/infra/model/RefEvent";
import { Period } from "../../framework/utils";
import { formatDate } from "../../framework/utils/formating";

/**
 * Policies when there are uploaded earnings (earnings can be empty or not)
 * 
 * validation: a function that returns a boolean to determine if the action should be done
 * 
 * action: a function that adds the event to the detail
 * 
 * warnings: a function that returns a list of warnings to be displayed to the user
 * 
 * @type {{[key: string]: {validation: (detail: RemittanceDetail, uploadedEarnings: {earnings: {isEmpty?: boolean}} | undefined) => boolean;
 * action: (detail: RemittanceDetail, options: {commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined) => Promise<any>;
 * warnings: (commit: boolean | undefined, params: any) => Promise<string[]>;}}}
 */
export const EarningUploadPolicies = {
    /**
     * Add a parental leave employment event (`lpl`) if the employment status is maternity and the maternity duration is over 18 weeks
     */
    parentalLeave: {
        /**
         * Checks if the action should be done - if the employment status is maternity and the maternity duration is over 18 weeks
         * @param {RemittanceDetail} detail 
         * @returns {boolean} true if the action should be done
         */
        validation: (detail) => {
            return Boolean(detail.employment?.isMaternityOver18weeks?.());
        },
        /**
         * Performs action to add the parental leave employment event (`lpl`)
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {Promise<null>} 
         */
        action: async (detail, options) => {
            const maternityDuration = detail.employment.employer.plan.currentRates.maternityDuration
            const parentalLeaveStart = moment(detail.employment.eventStatuses.last.effDt).add(maternityDuration + 1 , 'days')
            const parentalLeaveEvent = { code: 'lpl', ets: parentalLeaveStart.valueOf(), source: options?.eventsSource ?? EVENT_SOURCE.FILE.key, cmt: "Approved leave after maternity. [APP]" }
            detail.messages.add('autoPatLeave', [{key: 'parentalLeave', value: formatDate(parentalLeaveStart.valueOf())}]);
            // Update the employment if the commit option is true
            if(options?.commit) {
                detail.employment.addEvent(parentalLeaveEvent, {openRemittance: options?.openRemittance});
                await EmploymentService.updateEmployment(detail.employment, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return null;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean | undefined} commit flag if the action has been saved
         * @returns {Promise<string[]>} the warnings to show the user
         */
        warnings: async (commit) => Promise.resolve(commit ? ['addedParentalLeaveEvent'] : ['addParentalLeaveEvent'])
    },
    /**
     * Policy for the participation enrollement and "first day" events (on month other than January). See also the `eligEarningsNoHours` policy.
     * 
     * Add a `nrolFday` participation event if needed.Add the RTW employment event to the employment if needed
     * 
     * If the remittance detail's employment's participation in target period (not current period) is eligible period (participation status `ELI`),
     * and the remittance detail's earnings and hours are not empty or the uploaded earnings and hours are not empty,
     * and the participation doesn't already have a `nrolFday` event,
     * and the remittance detail's period is not January
     * 
     * Add `nrolFday` participation event with guessed date (remittance's period start)
     **/
    nrolFday: {
        /**
         * Checks if the action should be done - if the participation is eligible, there are earnings and hours, the period isn't January, and there is no first day event
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            // Don't enroll if the earnings has no hours or is onetime earnings (no hours)
            const hasUploadedEarnings = uploadedEarnings && uploadedEarnings.earnings?.total !== 0 && uploadedEarnings.earnings?.hours !== 0;

            const hasEarnings = detail.earnings?.total !== 0 && detail.earnings?.hours !== 0;
            if(!hasEarnings && !hasUploadedEarnings) {
                return false;
            }
            const hasFirstDayEvent = detail?.employment?.participation?.events?.find?.(e => e.config.isFirstDayEvent);
            const isJanPeriod = detail.period.moment.month() === Period.JAN_MONTH;
            const isEligibleInPeriod = detail.ppStatus.isEligiblePeriod();

            return !isJanPeriod && isEligibleInPeriod && !hasFirstDayEvent;
        },
        /**
         * Performs action to add the first day event
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {Promise<{ppIsNotEnrolled: boolean | undefined; hasAddedNrolPPEvent: boolean | undefined; nrolEventEffDate: number | undefined; 
         * nrolEventEmployerId: string | undefined; ppStatusCheckForRtwEvent: boolean | undefined; 
         * employmentsChecksForRtwEvent: {code: string; hasRtwEventAfterLeave: boolean; employmentIsOnLeave: boolean; 
         * hasHiredEventAfterLeave: boolean; shouldAddRtwEvent: boolean;}}>}
         */
        action: async (detail, options) => {
            const nrolFdayEvent = { code: 'nrolFday', ets: detail.period.timestampAtPeriodStart, guessed: true, source: options?.eventsSource ?? EVENT_SOURCE.FILE.key }
            // Update the participation
            // Need to add the event here for the checksBeforeUpdate
            detail.employment.participation.addEvent(nrolFdayEvent, {openRemittance: options?.openRemittance});
            detail.messages.add("elignNrolFday");
            const beforeUpdateChecks = await ParticipationService.checksBeforeUpdate(detail.employment.participation)
            if(options?.commit === false) {
                // rollback the changes, remove the event
                detail.employment.participation.deleteEvent(nrolFdayEvent);
            }else{
                // Will check if we need to add a RTW event to the employments of the participation, and add it if needed
                await ParticipationService.updateParticipation(detail.employment.participation, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return beforeUpdateChecks;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean} commit flag if the action has been saved
         * @param {RemittanceDetail} detail
         * @param {{employmentsChecksForRtwEvent: {code: string, shouldAddRtwEvent: boolean}[]}} beforeUpdateChecks this is returned by the action function
         * @returns {Promise<string[]>} the warnings to show the user
         */
        warnings: async (commit, detail, beforeUpdateChecks) => {
            const warnings = commit ? ['addedNrolfdayEvent'] : ['addNrolfdayEvent'];
            if(!commit){
                if(Boolean(beforeUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.shouldAddRtwEvent)){
                    warnings.push('addRtwEvent');
                }
            } else {
                const afterUpdateChecks = await ParticipationService.checksAfterUpdate(detail.employment.participation);
                if(
                    beforeUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.hasRtwEventAfterLeave === false && 
                    afterUpdateChecks.employmentsChecksForRtwEvent.find(x => x.code === detail.employment.employer.code)?.hasRtwEventAfterLeave
                ){
                    warnings.push('addedRtwEvent');
                }
            }
            return warnings;
        }
    },
    /**
     * Policy for the participation enrollement (on month January). See also the `eligEarningsNoHours` policy.
     * 
     * Add a `metElig` participation event if needed, on Jan 1st of that year, no guessed date
     * 
     * - If it's the January upload month
     * - The participation status for target period aka January (not current period) is eligible
     * - There are earnings and hours reported in January
     */ 
    nrolJan: {
        /**
         * Check if the action should be done - if the participation is eligible, the period is January, and there are earnings
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            const isJanPeriod = detail.period.moment.month() === Period.JAN_MONTH;
            // Don't enroll if the earnings has no hours or is onetime earnings (no hours)
            const hasUploadedEarnings = uploadedEarnings && uploadedEarnings.earnings?.total !== 0 && uploadedEarnings.earnings?.hours !== 0;
            const hasEarnings = detail.earnings?.pensionable !== 0 && detail.earnings?.pensionableHours !== 0;
            const isEligiblePeriod = detail.ppStatus.isEligiblePeriod();
            
            return isJanPeriod && isEligiblePeriod && (hasEarnings || hasUploadedEarnings);
        },
        /**
         * Performs the action of adding the metElig event to January 1st
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {Promise<null>}
         */
        action: async (detail, options) => {
            const nrolEvent = { 
                code: 'metElig', 
                ets: detail.period.timestampAtPeriodStart, 
                guessed: false, 
                source: options?.eventsSource ?? EVENT_SOURCE.FILE.key,
                cmt: `Eligible Jan 01 ${detail.period.year} as per the Eligibility Report + Earnings reported in January.  APP created NROL event as of Jan 1 [APP]`,
            }
            
            // Add NROL event to the participation
            detail.employment.participation.addEvent(nrolEvent, {openRemittance: options?.openRemittance});
            const beforeUpdateChecks = await ParticipationService.checksBeforeUpdate(detail.employment.participation)
            if (options?.commit === false) {
                // rollback the changes, remove the event
                detail.employment.participation.deleteEvent(nrolEvent);
            } else {
                await ParticipationService.updateParticipation(detail.employment.participation, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return beforeUpdateChecks;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean} commit flag if the action has been saved
         * @returns {Promise<string[]>} the warnings to show the user
         */
        warnings: async (commit) =>  Promise.resolve(commit ? ['addedNrolEvent'] : ['addNrolEvent']),
    },
    /**
     * Return a `eligEarningsNoHours` warning if needed. Do not do any action.
     * 
     * If the remittance detail's employment's participation in target period (not current period) is eligible period (participation status `ELI`),
     * and the remittance detail's earnings are not empty or the uploaded earnings are not empty, but the hours are empty
     * 
     * Return a `eligEarningsNoHours` warning
     **/
    eligEarningsNoHours: {
        /**
         * Checks if the action should be done - if the participation is eligible, there are earnings and hours, the period isn't January, and there is no first day event
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            // Don't enroll if the earnings has no hours or is onetime earnings (no hours)
            const hasUploadedEarnings = uploadedEarnings && uploadedEarnings.earnings?.total !== 0;
            const hasEarnings = detail.earnings?.total !== 0;
            if(!hasEarnings && !hasUploadedEarnings) {
                return false;
            }
            const hasHours = uploadedEarnings ? Boolean(uploadedEarnings.earnings?.hours) : Boolean(detail.earnings?.hours);

            const isJanPeriod = detail.period.moment.month() === Period.JAN_MONTH;
            const isEligibleInPeriod = detail.ppStatus.isEligiblePeriod();
            
            const hasFirstDayEvent = detail?.employment?.participation?.events?.find?.(e => e.config.isFirstDayEvent);
            // check if other policies would enroll (if the member had hours)
            const otherPoliciesWillEnrollIfHours = isEligibleInPeriod && (isJanPeriod ? true : !hasFirstDayEvent);

            return otherPoliciesWillEnrollIfHours && !hasHours;
        },
        /**
         * Do not do any action
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {Promise<{ppIsNotEnrolled: boolean | undefined; hasAddedNrolPPEvent: boolean | undefined; nrolEventEffDate: number | undefined; 
         * nrolEventEmployerId: string | undefined; ppStatusCheckForRtwEvent: boolean | undefined; 
         * employmentsChecksForRtwEvent: {code: string; hasRtwEventAfterLeave: boolean; employmentIsOnLeave: boolean; 
         * hasHiredEventAfterLeave: boolean; shouldAddRtwEvent: boolean;}}>}
         */
        action: async (detail, options) => {
            return;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean} commit flag if the action has been saved
         * @param {RemittanceDetail} detail
         * @param {{employmentsChecksForRtwEvent: {code: string, shouldAddRtwEvent: boolean}[]}} beforeUpdateChecks this is returned by the action function
         * @returns {Promise<string[]>} the warnings to show the user
         */
        warnings: async (commit, detail, beforeUpdateChecks) => {
            return ['eligEarningsNoHours'];
        }
    },
    /**
     * Add a RTW employment event if On Leave members have regular/overtime earnings uploaded in a remittance
     */
    returnToWorkAfterLeave: {
        /**
         * Checks if the action should be done - if the employment status is On Leave and the remittance detail has earnings
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            const earnings = uploadedEarnings?.earnings ?? detail.earnings;
            const hasUploadedEarnings = !earnings?.isEmpty;
            
            const employmentEvent = detail.empStatusEvent;
            const isOnLeaveAtStartOfMonth = employmentEvent.ets <= detail.period.timestampAtPeriodStart 
            const memberIsOnLeave = employmentEvent.status.isOnLeave();
            return hasUploadedEarnings && (memberIsOnLeave && isOnLeaveAtStartOfMonth);
        },
        /**
         * Performs action to add the RTW employment event (`rtw`)
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {Promise<null>} 
         */
        action: async (detail, options) => {
            const rtwEvent = { code: 'rtw', ets: detail.period.timestampAtPeriodStart, source: options?.eventsSource ?? EVENT_SOURCE.FILE.key,
                guessed: true, cmt: `Earnings reported in ${detail.periodText} month. Guessed return to work event added [APP]`};
            
            // Add remittance detail warnings
            if(detail.earnings.oneTime !== 0){
                detail.messages.add('oteEarningsOnLeave');
            }else if(detail.earnings.regular !== 0 || detail.earnings.overtime !== 0){
                detail.messages.add('earningsOnLeave');
            }
            // Update the employment if the commit option is true
            if(options?.commit) {
                detail.employment.addEvent(rtwEvent, {openRemittance: options?.openRemittance});
                await EmploymentService.updateEmployment(detail.employment, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return null;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean | undefined} commit flag if the action has been saved
         * @returns {Promise<string[]>} the warnings to show the user
         */
        warnings: async (commit) =>  Promise.resolve(commit ? ['addedRtwEvent'] : ['addRtwEvent'])
    },
    /**
     * Add a Unspecified Leave employment event (`lun`) with guessed date of 1st of  month when no earnings are reported and member is active-enrolled
     */
    unspecifiedLeave: {
        /**
         * Checks if the action should be done - if no earnings are reported and member is active-enrolled and the member is not already on leave
         * @param {RemittanceDetail} detail 
         * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings uploadedEarnings earnings uploaded by the user with an excel file
         * @returns {boolean} true if the action should be done
         */
        validation: (detail, uploadedEarnings) => {
            const earnings = uploadedEarnings?.earnings ?? detail.earnings;
            const hasNoUploadedEarnings = earnings?.isEmpty;
            /**
             * participation status is `ACT` (Enrolled)
             * @type {boolean | undefined}
             */
            const memberIsActive = detail.ppStatus?.isActive?.();
            /**
             * @type {boolean | undefined}
             */
            const memberIsOnLeave = detail.employmentStatus.isOnLeave();
            return Boolean(hasNoUploadedEarnings && memberIsActive && !memberIsOnLeave);
        },
        /**
         * Performs action to add the Unspecified Leave employment event (`lun`)
         * @param {RemittanceDetail} detail 
         * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options 
         * @returns {Promise<null>} 
         */
        action: async (detail, options) => {
            const unspecifiedLeaveEvent = { code: 'lun', ets: detail.period.timestampAtPeriodStart, source: options?.eventsSource ?? EVENT_SOURCE.FILE.key,
                guessed: true, cmt: `No earnings reported in ${detail.periodText} month. Guessed Unspecified leave event added [APP]`};
            detail.messages.add('noEarnings');
            // Update the employment if the commit option is true
            if(options?.commit) {
                detail.employment.addEvent(unspecifiedLeaveEvent, {openRemittance: options?.openRemittance});
                await EmploymentService.updateEmployment(detail.employment, {eventsSource: options?.eventsSource ?? EVENT_SOURCE.FILE.key, openRemittance: options?.openRemittance});
            }
            return null;
        },
        /**
         * Gets the warnings to show the user on the earnings upload summary modal, prior or post earnings upload
         * @param {boolean | undefined} commit flag if the action has been saved
         * @returns {Promise<string[]>} the warnings to show the user
         */
        warnings: async (commit) =>  Promise.resolve(commit ? ['addedUnspecifiedLeaveEvent'] : ['addUnspecifiedLeaveEvent'])
    },
}