import { Button, Card } from "react-bootstrap";
import { useMemo, useState } from "react";
import { Link } from 'react-router-dom'
import Loading from "../../../../components/containers/Loading";
import { AdjustmentService, EmploymentService } from "../../../../services";
import { renderNumberEmptyFor0 } from "../../../utils/renders";
import { formatAmount } from "../../../utils/formating";
import { adjustmentValidationConfig, adjustmentValidationTabsConfig } from "./adjustmentValidationConfig";
import { group } from "../../../../entities/pension/adjustment/AdjustmentConfigs";
import { Distribution } from "../../../../entities";
import TextareaAutosize from "react-textarea-autosize";
import useNotification from "../../../../hooks/useNotification";

const AdjustmentValidationItem = ({ data, instance, onClose }) => {
    const { addMessage } = useNotification();
    const { actual, expected, difference, earningAdj, contributionAdj } = data;
    const amounts = { actual, expected, difference };
    const [comment, setComment] = useState();
    const [isSecondStepWithAdjustment, setIsSecondStepWithAdjustment] = useState(false);
    const isMultipleAdjustments = useMemo(() => earningAdj && contributionAdj, [earningAdj, contributionAdj]);
    const containsAdjustment = useMemo(() => earningAdj || contributionAdj, [earningAdj, contributionAdj]);
    const openPeriod = containsAdjustment?.period;
    const remittanceURL = `/employer/${instance.employer.code}/remittance/${openPeriod?.value}`;

    const renderValue = (value, isAmount = true) => {
        return (<span className={value < 0 ? "text-danger" : ""}>{isAmount ? formatAmount(value, "") : renderNumberEmptyFor0(value, true)}</span>)
    }

    const handleSave = async () => {
        // Add comment to adjustments
        if (earningAdj && comment) earningAdj.cmt = comment;
        if (contributionAdj && comment) contributionAdj.cmt = comment;

        // Create adjustments
        if (earningAdj) await AdjustmentService.update(earningAdj);
        if (contributionAdj) await AdjustmentService.update(contributionAdj);

        // Remove the flag to show the task
        instance.events.remove((e) => e.config.isDeemedCheck);
        await EmploymentService.update(instance);

        addMessage(containsAdjustment ? `Adjustment${isMultipleAdjustments ? "s" : ""} added successfully` : "Task successfully resolved", 'success');
        
        // if no adjustments, close the task
        if (onClose && !containsAdjustment) onClose();
        if (containsAdjustment) setIsSecondStepWithAdjustment(true);
    }

    return <>
        {!amounts && <Loading />}
        {(amounts && !isSecondStepWithAdjustment) && 
            <div className="overflow-scroll">
                <div className="row-gap my-3">
                    {Object.values(adjustmentValidationConfig).map((item) => {
                        return (<Card className="w-fill full-framed" key={item.title}>
                        <Card.Body>
                            <Card.Title>{item.title}</Card.Title>
                            <Card.Subtitle className="mb-4 text-muted">{item.subtitle}</Card.Subtitle>
                            <Card.Text>
                                <p>Deemed earnings: {renderValue(item.earnings(amounts))}</p>
                                <p>Deemed hours: {renderValue(item.hours(amounts), false)}</p>
                                <p>Deemed contributions: {renderValue(item.contributions(amounts))}</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>)})}
                </div>
                {containsAdjustment && <><h3>Adjustment{isMultipleAdjustments ? "s" : ""}</h3>
                <p>The following adjustments should be made since the actual deemed contributions, earnings and/or hours currently in the app do not match the expected deemed amounts (see above).</p>
                <div className="row-gap mb-4">
                    {Object.values(adjustmentValidationTabsConfig).map((item) => {
                        const isEarningAdj = item.key === group.EARN;
                        const adj = isEarningAdj ? earningAdj : contributionAdj;
                        return adj && (<Card text="white" className="w-fill filled" key={item.key}>
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Text className="text-white mt-4">
                                    <p>Period: <b>{adj.period.text}</b></p>
                                    <p>Effective Period: <b>{adj.effDate}</b> to <b>{adj.endEffDate}</b></p>
                                    <p>Distribution:</p>
                                    <ul>
                                        {!isEarningAdj 
                                            ? adj.distributionContribution._list.map((item) => (<li>{Distribution.getDistributionOptions().find(opt => opt.key === item.ta)?.text}: <b>{renderValue(item.am)}</b></li>)) 
                                            : adj.distributionEarning._list.map((item) => (<li>{item.earningType.category.desc}: <b>{renderValue(item.amount)}</b> and <b>{renderValue(item.hours, false)}</b> hours</li>)) 
                                        }
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>)
                    })}
                </div>
                <div>
                    <label for="event-comment">Comment</label>
                    <TextareaAutosize 
                        id="event-comment"
                        minRows={4} 
                        maxRows={20}
                        onChange={(e) => setComment(e.target.value)} 
                        value={comment}
                        className="w-100 textarea" 
                    /> 
                </div></>}
            </div>}
        {isSecondStepWithAdjustment && <div className="d-flex flex-column justify-content-center align-items-center mt-4">
            <p>The open remittance period that the adjustment has been added to <b>must</b> be re-saved.</p>
            <Link component={Button} to={remittanceURL} target="_blank">Go to {instance.employer.code} {openPeriod.text} period</Link>
        </div>}
        {(amounts && !isSecondStepWithAdjustment) &&  <div className="d-flex justify-content-end mt-4">
            <Button onClick={handleSave}>{containsAdjustment ? `Create Adjustment${isMultipleAdjustments ? "s" : ""}` : "Resolve this task"}</Button>
        </div>}
    </>
}

export default AdjustmentValidationItem;