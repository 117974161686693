import { useEffect, useState, useRef } from "react"

import { Row } from '../../../framework/containers';
import { Button, Select } from '../../../framework/controls';
import { Excel } from '../../../framework/utils';
import { EInput, Message } from '../../../framework/components';
import { YearEndService, EmployerService } from '../../../services';
import { EarningType } from '../../../entities';
import UploadTemplateReport from "./UploadTemplateReport";
import { array2Object } from "../../../framework/utils/helper";
import Card from "../../../components/containers/Card";
import Loader from "../../../components/containers/Loading";

const YearEndReport = (props) => {

    const { employer } = props.params;

    //get year from 2020 to this year
    const yearsAsOfToday = Array.from({length: new Date().getFullYear() - 2020 + 1}, (_, i) => 2020 + i);
    const yearOptions = yearsAsOfToday.map(year => ({key: year.toString(), text: year.toString()}));

    const reportTypes = [
        {key: 'yearEnd', text: 'Year End Report'},
        {key: 'termination', text: 'Termination Report'}
    ]

    //initalize vars used below temporarily
    const [tableData, setTableData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [finalTime, setFinalTime] = useState();
    const [selectedYear, setSelectedYear] = useState(yearOptions[yearOptions.length - 1].text);
    const [reportType, setReportType] = useState(reportTypes[0].key);
    const [loaderText, setLoaderText] = useState('');
    const [earningTypes, setEarningTypes] = useState();
    const [errorPanel, setErrorPanel] = useState();
    const [progressText, setProgressText] = useState('Collecting data from server...');
    const progressBarRef = useRef(null);
    const progressTextRef = useRef(null);

    const isTerminatedReportSelected = reportType === 'termination';

    const updateProgress = (current, total, message) => {
        if (progressBarRef.current) {
            const percentage = (current / total) * 100;
            progressBarRef.current.style.width = `${percentage}%`;
            progressBarRef.current.setAttribute('aria-valuenow', percentage);
        }
        if (progressTextRef.current && message) {
            progressTextRef.current.textContent = message;
        }
    };

    const runReport = async () => {
        setIsLoading(true);
        const begin = Date.now();
        const employers = employer ? [employer] : (await EmployerService.getEmployers())?._list;
        let report = { details: [], processed: 0 };

        //pull all earning types for the header
        let earnTypes = {}
        employers.forEach((emp => {
            earnTypes = {...earningTypes, ...EarningType.splitEarningsTypesByCategory(emp.getActiveEarningTypes())};
        }))
        setEarningTypes(earnTypes);

        //direct export sheets, will export directly to sheet to save memory
        let processed = 0;
        for (let emp of employers) {
            setLoaderText((employers.length > 1 ? ' ('+processed+'/'+employers.length+') ' : '') + 'Processing ' + emp.code +'...')
            let response = await YearEndService.getData({
                employer: emp, 
                year: selectedYear, 
                setLoaderText, 
                terminatedOnly: isTerminatedReportSelected,
                onProgress: updateProgress
            });
            processed++;
            report = { 
                details: [...report.details, ...response.details],
                processed: (report.processed ?? 0) + response.processed,
                totalIncluded: (report.totalIncluded ?? 0) + response.totalIncluded
            }
        }
        
        report.earningTypes = earnTypes;
        setTableData(report);
        const end = Date.now();
        setFinalTime((end-begin)/1000/60);
    }

    const getData = () => {
        runReport().catch(error => setErrorPanel('Error running report: ' + error)).finally(() => setIsLoading(false));
    }

    const handleExportData = (data) => {
        const workbook = initExcelSheets(earningTypes);
        writeToSheets(workbook, data);
        return workbook.excel.download()
    }

    const handleSavingComment = async (details) => {
        setIsLoading(true);
        const detailBySin = array2Object(details, 'employment.person.sin');
        tableData.details.forEach(detail => {
            detail.details = detailBySin[detail.sin];
            detail.validation = {};
        });
        const newReport = await YearEndService.getData({ employer, year: selectedYear });
        setTableData(newReport);
        setIsLoading(false);
    }

    const initExcelSheets = (earningTypes) => {
        const excel = new Excel(`YearEnd-${selectedYear}-${employer ? employer.code : 'ALL'}-report`);
        const groupHeaders = YearEndService.initHeader(YearEndService.displayTypes.FULL, Excel, earningTypes, employer);
        const wsDetails = excel.addSheet(groupHeaders,[], 'Report');
        return { excel, wsDetails, groupHeaders };
    }

    const writeToSheets = (workbook, data) => {
        workbook.excel.addDataRows(workbook.wsDetails, workbook.groupHeaders, data.details)
    }

    useEffect(() => {
        setIsLoading(false);
        setErrorPanel(null);
    }, [tableData])

    return (<>
            <Card title={'Year End Report'} actions={[]} cn="mb10">
                    {errorPanel && <div className="mb-2">
                        <div className="alert alert-danger">{errorPanel}</div>
                    </div>}
                    <div className="line line-bottom g15">
                        <EInput name='year' label='Report Year' instance={selectedYear} >
                            <Select options={yearOptions} value={selectedYear} onChange={setSelectedYear} readOnly={isLoading}  />
                        </EInput> 
                        <EInput name='reportType' label='Report Type' instance={reportType}  >
                            <Select options={reportTypes} value={reportType} onChange={setReportType} readOnly={isLoading}/>
                        </EInput>
                        <Button type="primary" className="align-bottom bottom-offset" onClick={getData} disabled={isLoading}>{ isLoading ? <div className="d-flex align-items-center">{loaderText} <Loader color="#fff" height={15} width={30}/></div > : 'Run Report' }</Button>
                    </div>
                    {isTerminatedReportSelected && <Message message={{text: "Report will pull all “PENDING T/P/D” and all “EXPIRED LEAVES” as of December 31st of the year selected", severity:'info'}}/>}
                    { tableData && 
                        <div className="mt-3">
                            <div>Report ran in: {finalTime?.toFixed(2)} minutes.</div>
                            <div>Processed: {tableData.processed} employments.</div>
                            <div>Included in report: {tableData.totalIncluded} employments.</div>
                        </div> 
                    }
                    {isLoading && (
                        <>
                            <div className="progress mt-3">
                                <div 
                                    ref={progressBarRef}
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: '0%' }}
                                />
                            </div>
                            <div 
                                ref={progressTextRef} 
                                className="text-center mt-2"
                            >
                                Collecting data from server...
                            </div>
                        </>
                    )}
            </Card>

            {tableData &&
                <>
                    <Card title="Employer Report" actions={[]} cn="mb10" >
                        <Row className="justify-content-end">
                            <Button key="export" type="link" onClick={() => handleExportData(tableData)}>Export Data</Button>
                            <UploadTemplateReport fileName={`Year-End-Report-${employer?.code ?? ''}-${selectedYear}`} isMercer={false} report={tableData} onCommentSave={handleSavingComment} reportConfiguration={{selectedReportType: reportType}} />
                        </Row>
                    </Card>
                    <Card title="Mercer Report" actions={[]}>
                        <Row className="justify-content-end">
                            <UploadTemplateReport fileName={`Mercer-Report-${employer?.code ?? ''}-${selectedYear}`} isMercer={true} report={tableData}/>
                        </Row>
                    </Card>
                </>
            }
    </>)
}
export default YearEndReport;

