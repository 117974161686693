import React, { useState } from 'react';
import Button from '../../framework/controls/Button';
import StringInput from '../../components/filters/StringInput';
import { Definition } from '../../framework/infra';
import RefFilter from '../../framework/infra/model/RefFilter';

export default function ImportPageFilter({filters, setFilters, options}) {

    const [isAdding, setIsAdding] = useState(false);
    const filterInstance = new ImportPageFilterView();

    const toggleDropdown = () => {
        setIsAdding(!isAdding);
    }

    const handleMouseLeave = () => {
        setIsAdding(false);
    }

    const onEnter = () => {
        let label = options.find(x => x.value === filterInstance.message)?.text;
        setFilters(prevFilters => ({  messages: [
            ...(prevFilters.messages || []),
            {value: filterInstance.message, label: label}
        ]}));
        setIsAdding(false);
    }

    return ( <>
        <div className="dropdown-container filter-container bottom-offset" onMouseLeave={handleMouseLeave}> 
            <Button className={'bottom-offset no-mar btn-secondary'} onClick={toggleDropdown}>
                + Add filter 
            </Button>
            {isAdding &&
                <div className="dropdown-menu filter-menu card-panel-body" style={{minWidth: 500}}>
                    <StringInput onEnter={onEnter} options={options} label="Message" property="message" existingFilters={filters} instance={filterInstance}/>
                </div>
                }
            </div>
    </>

    );
}

class ImportPageFilterView extends RefFilter {

    constructor(data) {
        super(data, ImportPageFilterView.definitions);
    }

    static definitions = {
        message: { 
            isFilter: true,
            type: Definition.types.CHOICE, 
        },
    }
}
