import React, { useState, useEffect } from 'react'
import { Title, Row, Modal } from '../../framework/containers'
import { Button, Table } from '../../framework/controls'

import PersonSearchService from '../../services/search/PersonSearch'
import PersonForm from './PersonForm'
import Loading from '../../components/containers/Loading'
import PersonView from '../../entities/search/PersonSearch/PersonView'
import { PersonService } from '../../services'
import FilterContainer from '../../components/containers/FilterContainer'
import { Person } from '../../entities'

const searchAll = async (currentFilters) => {
    return PersonSearchService.scrollSearch(currentFilters);
}

const PersonSearch = ({ person, onSwitch, onCancel, onNew, validation, persistFilters = false, includeTitle = true, canAdd = true}) => {
	if (!person) person = new Person();
	const currentPageNumber = 1; 
	const defaultFilters = {}
	let pageSize = PersonSearchService.DEFAUT_PAGE_SIZE;
	if (person.firstName) defaultFilters.first = { value: person.firstName }
	if (person.lastName) defaultFilters.last = { value: person.lastName }
	if (person.maidenName) defaultFilters.maidenName = { value: person.maidenName }
	if (person.middleName) defaultFilters.middleName = { value: person.middleName }
	if (person.sin) defaultFilters.sin = { value: person.sin }

	const [filteredPeople, setFilteredPeople] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [newPerson, setNewPerson] = useState(false)
	const [loading, setLoading] = useState(false)
    const [currentFilters, setCurrentFilters] = useState();

	const columns = new Table.Headers(PersonView, 'sin, nameOriginal, dateOfBirth' )

    const loadPeople = (pageNumber = 1, scrollDuration, scrollId) => {
		setLoading(true);
		search({pageNumber, scrollDuration, scrollId}).then((results) => {
			setFilteredPeople(results.documents.all);
			setPaginationInfo({total: results.total, totalPages: results.totalPages, currentPage: pageNumber, pageSize});
			setLoading(false);
        });
    }

	const search = async ({pageNumber = 1, scrollDuration, scrollId}) => {
        if (currentFilters?.pageSize) pageSize = parseInt(currentFilters?.pageSize || pageSize);

        return PersonSearchService.search(currentFilters, pageNumber, pageSize, scrollDuration, scrollId);
	}

	useEffect(() => {
        if (currentFilters) loadPeople(currentPageNumber);
    }, [currentFilters]);

	const onSavedNew = (person) => {
		setNewPerson(false)
		onNew(person) 
	}

	const handleSwitch = (person) => {
		PersonService.get(person.id).then(personEntity => {
			if (onSwitch) onSwitch(personEntity)
		});
	}

    const onPageChange = (selectedPageNumber) => loadPeople(selectedPageNumber);

	return <>
		{includeTitle && <Title title='Person Search' onHide={onCancel} />}
		<Row className='mb-2 justify-content-between'>
			<FilterContainer view={PersonView} onUpdate={setCurrentFilters}
				exportFn={async () => searchAll(currentFilters)}
				persistent={persistFilters} defaults={defaultFilters} filterBoxWidth={300}/>
			{ canAdd && <Button key='new' className='btn-secondary no-mar' onClick={() => setNewPerson(true)}>+ New Person</Button> }
		</Row>
		{loading ? <Loading/> :
			<>
				<Table id='person-table' 
					data={filteredPeople} 
					columns={columns} 
					onSelect={handleSwitch}
					fitHeightToContent
					isPaginated
					paginationInfo={{onPageChange, ...paginationInfo}}
				/> 
			</>
		}
		{newPerson && <Modal id='personDetails' className='h-100 w-60 modal-bg-color'>
			<PersonForm person={
				new Person({sin: currentFilters.sin, firstName: currentFilters.first, lastName: currentFilters.last, middleName: currentFilters.middleName, maidenName: currentFilters.maidenName})
				}
				onSave={onSavedNew} onSwitch={handleSwitch} onCancel={() => setNewPerson(false)} validation={validation} />
		</Modal>}
	</>

}

export default PersonSearch;