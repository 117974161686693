import moment from 'moment';
import { MembershipBusiness } from "../../business";
import EmploymentBusiness from "../../business/EmploymentBusiness"
import { appCmt } from "../../framework/utils/helper";
import { EmploymentService, MembershipService, RemittanceDetailService } from "../../services"
import { EVENT_SOURCE as source } from "../../framework/infra/model/RefEvent";
import { Participation } from '../membership';

const systemSource = source.SYSTEM.key;
/**
 * Warnings about what was changed in the flow
 */
const warningMessages = {
    /** Employment created */
    NEW: {key: 'NEW', value: 'Employment created'},
    /** New employment will be created */
    WILL_NEW: {key: 'WILL_NEW', value: 'New employment will be created'},
    /** Employment updated */
    NO_CREATE: {key: 'NO_CREATE', value: 'Employment updated'},
    /** Employment will be updated */
    WILL_NO_CREATE: {key: 'WILL_NO_CREATE', value: 'Employment will be updated'},
    /** The employee did not meet age requirements to join the plan */
    AGE_JOIN: {key: 'AGE_JOIN', value: 'The employee did not meet age requirements to join the plan'},
    /** Not eligible. No date of birth on file */
    NO_DOB: {key: 'NO_DOB', value: 'Not eligible. No date of birth on file'},
    /** Hire date falls in a prior participation. Potential membership sustained. Remove and treat manually. */
    HIRE_DATE_IN_SUSTAINED_MEMBERSHIP: {key: 'HIRE_DATE_IN_SUSTAINED_MEMBERSHIP', value: 'Hire date falls in a prior participation. Potential membership sustained. Remove and treat manually.'},
    /** No Eligibility event in history. Not Eligible */
    NOT_FOUND: {key: 'NOT_FOUND', value: 'No Eligibility event in history. Not Eligible'},
    /** The employee did not meet age requirements to re-join the plan */
    AGE_REJOIN: {key: 'AGE_REJOIN', value: 'The employee did not meet age requirements to re-join the plan'},
    /** Send Member Declaration: Rehiring a Pensioner form */
    REHIRING: {key: 'REHIRING', value: 'Send Member Declaration: Rehiring a Pensioner form'},
    /** Found an expired on leave event */
    EXPIRED: {key: 'EXPIRED', value: 'Found an expired on leave event'},
    /** Employment marked as expired */
    MARK_EXP: {key: 'MARK_EXP', value: 'Employment marked as expired'},
    /** The employment on leave will be marked as expired */
    WILL_MARK_EXP: {key: 'WILL_MARK_EXP', value: 'The employment on leave will be marked as expired'},
    /** The first hire date in the new participation is older than the pending close date the of previous participation */
    ISSUE: {key: 'ISSUE', value: 'The first hire date in the new participation is older than the pending close date the of previous participation'},
    /** Employee was fired less than 60 days ago, employee is returning to work */
    F60: {key: 'F60', value: 'Employee was fired less than 60 days ago, employee is returning to work'},
    /** Employee returned to work */
    RTW: {key: 'RTW', value: 'Employee returned to work'},
    /** Employment already exists */
    CANT: {key: 'CANT', value: 'Employment already exists'},
    /** Eligibility expired in previous participation */
    ELIG_EXP: {key: 'ELIG_EXP', value: 'Eligibility expired in previous participation'},
    /** Employee is eligible to join the plan. Confirm first day of work */
    ELIG: {key: 'ELIG', value: 'Employee is eligible to join the plan. Confirm first day of work'},
    /** Identified as Eligible in Eligibility Report */
    ELIG_BY_EMPLOYER: {key: 'ELIG_BY_EMPLOYER', value: 'Identified as Eligible in Eligibility Report'},
    /** Identified as Not Eligible in Eligibility Report */
    NELIG_BY_EMPLOYER: {key: 'NELIG_BY_EMPLOYER', value: 'Identified as Not Eligible in Eligibility Report'},
    /** Will now be working at multiple employers */
    MER: {key: 'MER', value: 'Will now be working at multiple employers'},
}

/**
 * Infos about what was changed in the flow
 */
const infoMessages = {
    /** Will create a new participation */
    WILL_CREATE_NEW_PP: {key: 'WILL_CREATE_NEW_PP', value: "Will create a new participation"},
    /** Created a new participation */
    CREATED_NEW_PP: {key: 'CREATED_NEW_PP', value: "Created a new participation"},
    /** Will update the last participation */
    WILL_UPDATE_LAST_PP: {key: 'WILL_UPDATE_LAST_PP', value: "Will update the last participation"},
    /** Updated the last participation */
    UPDATED_LAST_PP: {key: 'UPDATED_LAST_PP', value: "Updated the last participation"},

    /** Will create a new employment */
    WILL_CREATE_NEW_EMP: {key: 'WILL_CREATE_NEW_EMP', value: "Will create the new employment"},
    /** Created a new employment */
    CREATED_NEW_EMP: {key: 'CREATED_NEW_EMP', value: "Created the new employment"},
    /** Will not create a new employment */
    NOT_WILL_CREATE_NEW_EMP: {key: 'NOT_WILL_CREATE_NEW_EMP', value: "Will not create a new employment"},
    /** Did not create a new employment */
    NOT_CREATED_NEW_EMP: {key: 'NOT_CREATED_NEW_EMP', value: "Did not create a new employment"},
    /** Will update an existing employment */
    WILL_UPDATE_EMP: {key: 'WILL_UPDATE_EMP', value: "Will update an existing employment"},
    /** Updated an existing employment */
    UPDATED_EMP: {key: 'UPDATED_EMP', value: "Updated an existing employment"},

    /** Will update another existing employment */
    WILL_UPDATE_OTHER_EMP: {key: 'WILL_UPDATE_OTHER_EMP', value: "Will update the other employment"},
    /** Updated another existing employment */
    UPDATED_OTHER_EMP: {key: 'UPDATED_OTHER_EMP', value: "Updated the other employment"},

    /** Will not do any changes to the employments or participations */
    NOT_WILL_DO_CHANGE: {key: 'NOT_WILL_DO_CHANGE', value: "Will not do any changes to the employments or participations"},
    /** Did not do any changes to the employments or participations */
    NOT_DID_CHANGE: {key: 'NOT_DID_CHANGE', value: "Did not do any changes to the employments or participations"},
}

const getParticipationNoLabel = (participation) => {
    return `no #${participation.no} ${participation.ppNo !== participation.no ? ('(' + participation.ppNo + ')') : ''}`
}

/**
 * Helper to get the data for a RTW event.
 * 
 * Sets the code, ets, guessed and source props.
 * @param {{date: Date, options: {source: string | undefined; isHiredSourceSystem: boolean | undefined; eligibilityEvent: ParticipationEvent | undefined;} | undefined;}} options
 * @returns {{code: string; ets: Date; guessed: boolean; source: string | undefined;}}
 * }
 */
const getRTWEventData = ({date, options}) => {
    return {code: 'rtw', ets: date, guessed: Boolean(options?.isHiredSourceSystem), source: options?.source};
}

/**
 * 
 * @param {Employment} employment 
 * @param {{end?: boolean | undefined; noCommit?: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; eligibilityEvent: ParticipationEvent | undefined;} | undefined} options 
 * @returns {Promise<{ warning?: string | undefined; employment: Employment; membership: Membership; newEmploymentCreated?: boolean | undefined; noAction: boolean | undefined; messages: {warnings: {key: string; customValue: string;}[]; infos: {key: string; customValue: string;}[]};} | void>}
 */
async function create (employment, options) {

    const manageFunctions = [
        manageNew,
        managePensioner,
        manageClosed,
        managePending,
        manageActive,
        manageIneligible,
        manageEligible,
    ]
    /** @type {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} */
    const messages = { warnings: [], infos: [] };
    /** @type {{ warning?: string | undefined; newEmploymentCreated?: boolean | undefined; noAction: boolean | undefined; messages: {warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}}} */
    let results = {warning: '', messages};

    const hireSource = employment.getHiredEvent().source;
    /** flag needed to know if the added RTW event is guessed. */
    const isHiredSourceSystem = hireSource === source.SYSTEM.key || hireSource === source.FILE.key;
    for (const manageFunction of manageFunctions) {
        results = await manageFunction(employment, employment.participation.membership, messages, {...options, isHiredSourceSystem});
        //if we save an employment
        if (results.employment) {
            const returnMessages = {
                warnings: messages.warnings.map(x => ({key: x.key, customValue: x.value + (x.customMessage ? ` ${x.customMessage}` : '')})),
                infos: messages.infos.map(x => ({key: x.key, customValue: x.value + (x.customMessage ? ` ${x.customMessage}` : '')})),
            }
            return {...results, 
                messages: returnMessages,
                // add warning for backward compatibility
                warning: messages.warnings.length ? messages.warnings.map(x => x.value + (x.customMessage ? ` ${x.customMessage}` : '')).join('. ') : '',
            };
        }
    }
}

/**
 * 
 * @param {Employment} employment 
 * @param {{participation: Participation; cmt: string | undefined; ppCode: string | undefined; noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined;}} params 
 * @returns 
 */
async function splitEmployment(employment, params) {

    const pp = params.participation;
    const oldKeys = employment.keyValue;
    pp.employments.remove(emp => emp.keyValue === employment.keyValue);
    employment.getHiredEvent().cmt = appCmt(params.cmt ?? '');
    await EmploymentBusiness.createNewPPForEmp(employment, employment.membership, {openRemittance: params.openRemittance, eventsSource: params.eventsSource});
    const newKeys = employment.keyValue;

    if(!pp.isPendingClose()){
        pp.addEvent({code : 'penTrm', source: params?.eventsSource || systemSource}, {openRemittance: params?.openRemittance});
    }

    if(params.ppCode) {
        pp.addEvent({code: params.ppCode, source: params?.eventsSource || systemSource}, {openRemittance: params?.openRemittance});
    }

    if (params.noCommit) {
        return Promise.resolve(employment)
    } else {
        await EmploymentService.update(employment)
        await RemittanceDetailService.transferRemittanceDetails(employment.hiredDate, oldKeys, newKeys)
        await EmploymentService.deleteEmptyEmployment(oldKeys)
        await MembershipService.update(employment.participation.membership)
    }
}

/**
 * Add the `tex` emp event (Leave Expired) in the employment on the provided date.
 * 
 * If not `noCommit`, save the employment and the employment's participation's membership
 * @param {Employment} employment 
 * @param {{ets?: number | undefined; noCommit?: boolean | undefined; eventsSource: string | undefined; openRemittance?: Remittance | undefined;}} params 
 */
async function expireEmployment(employment, params) {
    employment.addEvent({code: 'tex', ets: params.ets, source: params.eventsSource || systemSource}, {openRemittance: params?.openRemittance});
    if (!params.noCommit) {
        await EmploymentService.update(employment);
        await MembershipService.update(employment.participation.membership);
    }
}

/**
 * Manage creating a new participation for the new employment (eligible or not, depending on age requirement).
 * 
 * - if the member has the required age to rejoin the plan:
 *   - if special case where the elig upload explicitly set the eligibility:
 *     - if is eligible: add a `ELIG_BY_EMPLOYER` warning, create a new participation (with a `metEligDate` pp event), and call {@link saveChanges} to save and end the flow
 *     - otherwise if is ineligible: add a `NELIG_BY_EMPLOYER` warning, create a new participation (with a `inegDes` pp event), and call {@link saveChanges} to save and end the flow
 *   - otherwise (required age, not special case): add a `NOT_FOUND` warning, create a new participation (with a `inegNotF` pp event), and call {@link saveChanges} to save and end the flow
 * - otherwise (not required age): add a `AGE_JOIN` warning, create a new participation (with a `inegOvAge` pp event), and call {@link saveChanges} to save and end the flow
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; infos: string[] | undefined;} | undefined} options 
 * @returns 
 */
async function manageAgeRequirement(employment, membership, messages, options) {
    if (employment.hasAgeToRejoin()) {

        // Special case where the elig upload explicitly set the employee as eligible
        if (options.eligibilityEvent) {
            if (options.eligibilityEvent.status.isEligible()) {
                await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'metEligDate', cmt: options.eligibilityEvent.cmt, ets: options.eligibilityEvent.ets, openRemittance: options?.openRemittance, eventsSource: options?.eventsSource})
                addWarn(messages, warningMessages.ELIG_BY_EMPLOYER)
                addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event metEligDate (Meets eligibility on this date)`)
                addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
                return saveChanges(messages, employment, membership, true, options);
            }
            if (options.eligibilityEvent.status.isIneligible()) {
                await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'inegDes', cmt: options.eligibilityEvent.cmt, ets: options.eligibilityEvent.ets, openRemittance: options?.openRemittance, eventsSource: options?.eventsSource})
                addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event inegDes (Not Eligible - As designated by Employer)`)
                addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
                addWarn(messages, warningMessages.NELIG_BY_EMPLOYER)
                return saveChanges(messages, employment, membership, true, options);
            }
        }

        const newEventParams = {code: 'inegNotF', cmt: appCmt(warningMessages.NOT_FOUND.value), employment, openRemittance: options?.openRemittance, eventsSource: options?.eventsSource};
        await EmploymentBusiness.createNewPPForEmp(employment, membership, newEventParams)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event inegNotF (Not identified as Eligible)`)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
        addWarn(messages ,warningMessages.NOT_FOUND)
        return saveChanges(messages, employment, membership, true, options);
    } else {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'inegOvAge', cmt: appCmt(warningMessages.AGE_JOIN.value), openRemittance: options?.openRemittance, eventsSource: options?.eventsSource})
        addWarn(messages ,warningMessages.AGE_JOIN)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event inegOvAge (Not identified as Eligible)`)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
        return saveChanges(messages, employment, membership, true, options);
    }
}

/**
 * Manage creating a new participation for the new employment.
 * 
 * - if there is a last participation, return (continue the flow, next one should be {@link managePensioner}).
 * - otherwise if the person does not have a date of birth: add a `NO_DOB` warning, create a new participation (with a `inegNotF` pp event), and call {@link saveChanges} to save and end the flow
 * - otherwise (need to create a new pp), continue to {@link manageAgeRequirement}
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function manageNew(employment, membership, messages, options) {
    /** @type {Participation} */
    const lastParticipation = membership.participations.last
    if (lastParticipation) return { messages };

    if (!employment.person.dob) {
        const newEventParams = {code: 'inegNotF', cmt: appCmt(warningMessages.NO_DOB.value), openRemittance: options?.openRemittance, eventsSource: options?.eventsSource};
        await EmploymentBusiness.createNewPPForEmp(employment, membership, newEventParams)
        addWarn(messages ,warningMessages.NO_DOB)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event inegNotF (Not identified as Eligible)`)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new ineligible participation ${getParticipationNoLabel(employment.participation)}`)
        return saveChanges(messages, employment, membership, true, options);
    }

    return manageAgeRequirement(employment, membership, messages, options);
}

/**
 * Manage creating an employment for a pensioner.
 * 
 * - if the member is not receiving pension, return (continue the flow, next one should be {@link manageClosed}).
 * - otherwise if the last participation is active but NOT pending closed: continue to {@link manageActive}
 * - otherwise, if the member has age to rejoin the plan: add a `REHIRING` warning, create a new participation (with a `penRhdA` pp event), and call {@link saveChanges} to save and end the flow
 * - otherwise: create a new participation (with `AGE_REJOIN` warning and a `penRhdD` pp event) and call {@link saveChanges} to save and end the flow
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function managePensioner(employment, membership, messages, options) {
    if (!membership.isReceivingPension(employment.hiredDate, employment.employer.plan.jurisdiction)) return { messages };
    
    // check if the last participation is active but NOT pending closed
    if (membership.participations.last.status.isActive() && !membership.participations.last.isPendingClose()) {
        return manageActive(employment, membership, messages, options);
    }

    // pensioners and pending statuses will be handled here
    if (employment.hasAgeToRejoin()) {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'penRhdA', cmt: appCmt(warningMessages.REHIRING.value), openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
        addWarn(messages, warningMessages.REHIRING)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event penRhdA (Pensioner: Rehired - Not Enrolled)`)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
        return saveChanges(messages, employment, membership, true, options);
    } else {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'penRhdD', cmt: appCmt(warningMessages.AGE_REJOIN.value), openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
        addWarn(messages, warningMessages.AGE_REJOIN)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event penRhdD (Pensioner: Rehired - Not Eligible)`)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
        return saveChanges(messages, employment, membership, true, options);
    } 
}

/**
 * Manage adding a new employment to a closed participation.
 * 
 * - if the employment's hire date is in range of a previous closed participation, add warning `HIRE_DATE_IN_SUSTAINED_MEMBERSHIP`, and call {@link saveChanges} with `options.end` to true and `created` false (to end the flow without saving).
 * - otherwise if the last participation not close: return (continue the flow, next one should be {@link managePending}).
 * - otherwise (if not edge case and last pp is closed, need to create a new pp), continue to {@link manageAgeRequirement}
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function manageClosed(employment, membership, messages, options) {
  // check if the employment's hire date is in range of a closed participation

  const hireDateEts = employment.getHiredEvent().ets;
  /** @type {Participation | undefined} */
  const lastParticipation = membership.participations.last;
  /** @type {Participation | undefined} */
  const lastButOneParticipation =
    membership.participations.all[membership.participations.all.length - 2];

  if (
    lastButOneParticipation?.lastCloseDate &&
    moment(hireDateEts).isSameOrBefore(
      moment(lastButOneParticipation.lastCloseDate).add(60, "days")
    )
  ) {
    // Do not create the employment, return a warning
    const newOptions = {
      ...(options ?? {}),
      end: true,
    };
    addWarn(messages, warningMessages.HIRE_DATE_IN_SUSTAINED_MEMBERSHIP);
    addInfo(messages, options?.noCommit ? infoMessages.NOT_WILL_DO_CHANGE : infoMessages.NOT_DID_CHANGE)
    return saveChanges(
      messages,
      employment,
      membership,
      false,
      newOptions
    );
  }

  // otherwise check the rest of the flow

  if (!lastParticipation.status.isClose()) return { messages };

  return manageAgeRequirement(employment, membership, messages, options);
}

/**
 * Manage adding a new employment to a pending participation.
 * 
 * - expire all employments of the last participation if they have outstanding leaves
 * - if the last participation is not "pending but not close", return (continue the flow, next one should be {@link manageActive}).
 * - otherwise (pp is "pending but not close") if the last participation has employments recently fired/quit (< 60 days):
 *   - remove the "pending" events from the last participation's events
 *   - if the new employment is for the same employer of a recently fired/quit employment (< 60 days): add a `F60` warning, update the existing employment (add a `rtw` emp event on hire date), and call {@link saveChanges} (with `created` false) to save and end the flow
 *   - otherwise if it's a Switch-out, Switch-in (there are other employments recently fired/quit): update the last fired/quit employment (with a `tsw` emp event on hire date), add a `swi` emp event on hire date to the new employment, add the new employment in the last participation, and call {@link saveChanges} to save and end the flow
 * - otherwise (pp is "pending but not close" and no recent fired/quit emps, need to create a new pp): continue to {@link manageAgeRequirement}
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function managePending(employment, membership, messages, options) {
    /** @type {Participation} */
    const lastParticipation = membership.participations.last
    const hireDateEts = employment.getHiredEvent().ets; 

    //expire all employments if they have outstanding leaves
    await expireEmployments(lastParticipation, hireDateEts, options?.noCommit, messages, options);
    if (!lastParticipation.isPendingClose()) return { messages };

    const employmentsFiredWithin60days = getEmploymentsFiredWithin60days(lastParticipation, employment.hiredDate);

    // some employments were recently fired quit, re-open this pending pp
    if (employmentsFiredWithin60days.length > 0) {
        /** @type {Employment | undefined} */
        const existingEmployment = employmentsFiredWithin60days.find(ee => ee.employer.keyValue === employment.employer.keyValue);
        
        lastParticipation.events.pullFilter((e => !e.config.isPendingEvent));
        addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_LAST_PP : infoMessages.UPDATED_LAST_PP, `${getParticipationNoLabel(employment.participation)} that was pending but not close: removed all "pending" events`)

        if (existingEmployment) { //end process
            employment = existingEmployment;
            employment.addEvent(getRTWEventData({date: hireDateEts, options: {isHiredSourceSystem: options?.isHiredSourceSystem, source: options?.eventsSource}}), {openRemittance: options?.openRemittance});
            addWarn(messages, warningMessages.F60)
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_EMP : infoMessages.UPDATED_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that was pending but not close): add event rtw (Return to work), because employment was Fired/Quit less than 60 days ago`)
            return saveChanges(messages, employment, membership, false, options);
        }

        //Switch-out, Switch-in
        const lastEmployment = getLastFiredEmployment(lastParticipation.employments.all);
        let addedSWI = false;
        if (lastEmployment.employer.keyValue !== employment.employer.keyValue) {
            lastEmployment.addEvent({code: 'tsw', ets: hireDateEts, source: options?.eventsSource || systemSource }, {openRemittance: options?.openRemittance});
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_OTHER_EMP : infoMessages.UPDATED_OTHER_EMP, `of employer ${lastEmployment.employer.code} in the last participation ${getParticipationNoLabel(employment.participation)} (that was pending but not close): add event tsw (SWO - Switched-Out)`)
            if (!options?.noCommit) await EmploymentService.update(lastEmployment);
            employment.addEvent({code: 'swi', ets: hireDateEts, source: options?.eventsSource || systemSource}, {openRemittance: options?.openRemittance});
            addedSWI = true;
        }

        await EmploymentBusiness.mergeEmployment(employment, lastParticipation, undefined, undefined, {openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that was pending but not close)` + addedSWI ? ` and add event swi (SWI - Switched-In)` : '')
        return saveChanges(messages, employment, membership, true, options);
    } 
    // make new participation since employments fired a while ago
    else { 
        return manageAgeRequirement(employment, membership, messages, options);
    }
}

/**
 * Manage adding a new employment to an active participation.
 * 
 * - if the last participation is not active, return (continue the flow, next one should be {@link manageIneligible}).
 * - otherwise (pp is active):
 *   - add a `mer` emp event (on hire date) to all Active or OnLeave employments in the last participation and save them.
 *   - add a `mer` emp event (on hire date) to the new employment.
 *   - if there was an existing employment (an employment with the same employer in the last participation):
 *     - if the existing employment is active: add a `CANT` warning, and call {@link saveChanges} with `options.end` to true and `created` false (to end the flow without saving).
 *     - otherwise (existing emp not active): add a `RTW` warning, update the existing employment (add a `rtw` emp event on hire date), and call {@link saveChanges} (with `created` false) to save and end the flow
 *   - otherwise (pp is active and no existing emp): add the new employment in the last participation, and call {@link saveChanges} to save and end the flow
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function manageActive(employment, membership, messages, options) {

    /** @type {Participation} */
    const lastParticipation = membership.participations.last;
    if (!lastParticipation.status.isActive()) return { messages };

    let hireDateEts = employment.getHiredEvent().ets; 

    /** Existing employment for the same employer in the last participation
     * @type {Employment | undefined}
     */
    let existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
    /** Active or OnLeave employments in the last participation
     * @type {Employment[] | Employments}
     */
    let activeEmployments = lastParticipation.employments.filter(x=>x.status.isActive() || x.status.isOnLeave());

    for (let activeEmployment of activeEmployments) {
        if(!activeEmployment.getMerEvent()) {
            activeEmployment.addEvent({code: 'mer', ets: hireDateEts, source: options?.eventsSource || systemSource}, {openRemittance: options?.openRemittance});
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_OTHER_EMP : infoMessages.UPDATED_OTHER_EMP, `of employer ${activeEmployment.employer.code} in the last participation ${getParticipationNoLabel(employment.participation)} (that is active): add event mer (Working at Multiple Employers)`)
            if (!options?.noCommit){
                await EmploymentService.update(activeEmployment);
            }
        }
    }
    employment.addEvent({code: 'mer', ets: hireDateEts, source: options?.eventsSource || systemSource}, {openRemittance: options?.openRemittance}); // note: reverted if existingEmployment because it will execute employment = existingEmployment

    if (existingEmployment) {
        //guard, we shoudln't get here with an active ER
        if (existingEmployment.status.isActive()) {
            addWarn(messages, warningMessages.CANT);
            addInfo(messages, options?.noCommit ? infoMessages.NOT_WILL_DO_CHANGE : infoMessages.NOT_DID_CHANGE)
            return saveChanges(messages, employment, membership, false, {end: true});
        }
        employment = existingEmployment;
        employment.addEvent(getRTWEventData({date: hireDateEts, options: {isHiredSourceSystem: options?.isHiredSourceSystem, source: options?.eventsSource}}), {openRemittance: options?.openRemittance});
        addWarn(messages, warningMessages.RTW)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_EMP : infoMessages.UPDATED_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is active), and add event rtw (Return to work)`)
        return saveChanges(messages, employment, membership, false, options);
    } else {
        addWarn(messages, warningMessages.MER)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is active) with event mer (Working at Multiple Employers)`)
        await EmploymentBusiness.mergeEmployment(employment, lastParticipation, undefined, undefined, {openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
        return saveChanges(messages, employment, membership, true, options);
    } 
}

/**
 * Manage adding a new employment to an ineligible participation.
 * 
 * - if the last participation is not ineligible, return (continue the flow, next one should be {@link manageEligible}).
 * - otherwise if there was an existing employment (an employment with the same employer in the last participation): add a `RTW` warning, update the existing employment (add a `rtw` emp event on hire date), and call {@link saveChanges} (with `created` false) to save and end the flow
 * - otherwise (pp ineligible an no existing emp): add the new employment in the last participation, and call {@link saveChanges} to save and end the flow
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function manageIneligible(employment, membership, messages, options) {
    const lastParticipation = membership.participations.last
    if (!lastParticipation.status.isIneligible()) return { messages };

    let existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
    if (!existingEmployment) {
        await EmploymentBusiness.mergeEmployment(employment, lastParticipation, undefined, undefined, {openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is ineligible)`)
        return saveChanges(messages, employment, membership, true, options);
    } else {
        const hireDateEts = employment.getHiredEvent().ets; 
        employment = existingEmployment;
        employment.addEvent(getRTWEventData({date: hireDateEts, options: {isHiredSourceSystem: options?.isHiredSourceSystem, source: options?.eventsSource}}), {openRemittance: options?.openRemittance});
        addWarn(messages, warningMessages.RTW)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_EMP : infoMessages.UPDATED_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is ineligible), and add event rtw (Return to work)`)
        return saveChanges(messages, employment, membership, false, options);
    }
}

/**
 * Manage adding a new employment to an eligible participation.
 * 
 * - if the last participation is not eligible:
 *   - if there was an existing employment (an employment with the same employer in the last participation): add a `RTW` warning, update the existing employment (add a `rtw` emp event on hire date), and call {@link saveChanges} (with `created` false) to save and end the flow
 *   - otherwise (no existing employment): add a `NOT_FOUND` warning, add a `inegNotF` pp event in the last participation, add the new employment in the last participation, and call {@link saveChanges} to save and end the flow
 * - otherwise if the last participation is still eligible with the new employment: the employment's hired date is before the participation's eligibility end date (1 year after the last eligibility event date)
 *   - if there was an existing employment (an employment with the same employer in the last participation): add a `RTW` warning, update the existing employment (add a `rtw` emp event on hire date), and call {@link saveChanges} (with `created` false) to save and end the flow
 *   - otherwise (no existing employment): add a `ELIG` warning, add the new employment in the last participation, and call {@link saveChanges} to save and end the flow
 * - otherwise (last pp is not eligible with the new employment): add a `ppExp` pp event in the last participation (possible bug: not persisted?), add a `tclCan` emp event to each employment in the last participation and update it, add a `ELIG_EXP` warning, create a new participation (with a `ineg` pp event), and call {@link saveChanges} to save and end the flow
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{noCommit: boolean | undefined; openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns 
 */
async function manageEligible(employment, membership, messages, options) {
    const lastParticipation = membership.participations.last
    if (!lastParticipation.status.isEligiblePeriod()) {
        const hireDateEts = employment.getHiredEvent().ets; 
        const existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
        if (existingEmployment) { //if the employment exists, we can just rtw since they weren't part of a plan
            employment = existingEmployment;
            employment.addEvent(getRTWEventData({date: hireDateEts, options: {isHiredSourceSystem: options?.isHiredSourceSystem, source: options?.eventsSource}}), {openRemittance: options?.openRemittance});
            addWarn(messages, warningMessages.RTW)
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_EMP : infoMessages.UPDATED_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is eligible), and add event rtw (Return to work)`)
            return saveChanges(messages, employment, membership, false, options);
        } else {
            await EmploymentBusiness.mergeEmployment(employment, lastParticipation, 'inegNotF', warningMessages.NOT_FOUND.value, {openRemittance: options?.openRemittance, eventsSource: options?.eventsSource})
            addWarn(messages, warningMessages.NOT_FOUND)
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_LAST_PP : infoMessages.UPDATED_LAST_PP, `${getParticipationNoLabel(employment.participation)} that is eligible: add inegNotF event (Not identified as Eligible)`)
            addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is ineligible)`)
            return saveChanges(messages, employment, membership, true, options);
        }
    }
    
    employment.participation = lastParticipation;
    if (employment.isStillEligible()) {
        const existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
        if (existingEmployment) {
            const hireDateEts = employment.getHiredEvent().ets; 
            employment = existingEmployment;
            employment.addEvent(getRTWEventData({date: hireDateEts, options: {isHiredSourceSystem: options?.isHiredSourceSystem, source: options?.eventsSource}}), {openRemittance: options?.openRemittance});
            addWarn(messages, warningMessages.RTW)
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_EMP : infoMessages.UPDATED_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is eligible), and add event rtw (Return to work)`)
            return saveChanges(messages, employment, membership, false, options);
        } else {
            await EmploymentBusiness.mergeEmployment(employment, lastParticipation, undefined, undefined, {openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
            addWarn(messages, warningMessages.ELIG)
            addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in the last participation ${getParticipationNoLabel(employment.participation)} (that is eligible)`)
            return saveChanges(messages, employment, membership, true, options);
        }
    } else {
        lastParticipation.addEvent({code: "ppExp", source: options?.eventsSource || systemSource}, {openRemittance: options?.openRemittance});
        
        for (let ppEmployment of lastParticipation.employments.all) {
            ppEmployment.addEvent({code: 'tclCan', source: options?.eventsSource || systemSource}, {openRemittance: options?.openRemittance});
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_OTHER_EMP : infoMessages.UPDATED_OTHER_EMP, `of employer ${ppEmployment.employer.code} in the last participation ${getParticipationNoLabel(employment.participation)} (that is not still eligible): add event tclCan (Employment Closed - Eligibility period has expired)`)
            if (!options?.noCommit) await EmploymentService.update(ppEmployment);
        }
            
        await EmploymentBusiness.createNewPPForEmp(employment, membership, { code: 'ineg', openRemittance: options?.openRemittance, eventsSource: options?.eventsSource});
        addWarn(messages, warningMessages.ELIG_EXP)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_PP : infoMessages.CREATED_NEW_PP, `${getParticipationNoLabel(employment.participation)} with event ineg (Not Eligible - Employee is not eligible)`)
        addInfo(messages, options?.noCommit ? infoMessages.WILL_CREATE_NEW_EMP : infoMessages.CREATED_NEW_EMP, `in new participation ${getParticipationNoLabel(employment.participation)}`)
        return saveChanges(messages, employment, membership, true, options);
    }
}

/**
 * Get the employments where the hired date is not more than 60 days after the Fired/Quit date of the employment
 * 
 * For example, if the hired date is on May 30st, finds all employments where their Fired/Quit date is after April 1st
 * (April 1st + 60 days = May 30th)
 * @param {Participation} participation 
 * @param {*} hiredDate 
 * @returns {Employment[]}
 */
function getEmploymentsFiredWithin60days(participation, hiredDate) {
    const employmentsWithin60Days = [];
    /** @type {Employment[]} */
    const employments = participation.employments.all;
    for (const employment of employments) {
        if (employment.isFiredQuitWithin60Day(hiredDate)) {
            employmentsWithin60Days.push(employment);
        }
    }
    return employmentsWithin60Days;
}

/**
 * Expire the employments of the participation (add a Leave Expired event to the employments)
 * 
 * Find the expired employments (the employment's last status event is expired in the employer's jurisdiction as of the provided date). For each:
 * - add a `EXPIRED` warning
 * - add a `MARK_EXP` warning (or `WILL_MARK_EXP` warning if no commit)
 * - expire the employment: 
 *   - add the `tex` emp event (Leave Expired) in the employment.
 *   - if not `noCommit`, save the employment and the employment's participation's membership
 * @param {Participation} participation 
 * @param {*} date 
 * @param {boolean | undefined} noCommit 
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {{openRemittance: Remittance | undefined; eventsSource: string | undefined; isHiredSourceSystem: boolean | undefined;} | undefined} options 
 * @returns {void} The warning messages have been added to `messages`
 */
async function expireEmployments(participation, date, noCommit, messages, options) {
    for (let employment of participation.employments.all) {
        const expirationEvent = employment.isExpiredAsOf(date)
        if (expirationEvent) {
            addWarn(messages, warningMessages.EXPIRED, `(${employment.employer.code})`);
            addWarn(messages, noCommit ? warningMessages.WILL_MARK_EXP : warningMessages.MARK_EXP);
            addInfo(messages, options?.noCommit ? infoMessages.WILL_UPDATE_OTHER_EMP : infoMessages.UPDATED_OTHER_EMP, `of employer ${employment.employer.code} in the last participation with ${getParticipationNoLabel(employment.participation)}: add event tex (Leave Expired)`)
            await expireEmployment(employment, {noCommit, ets: expirationEvent.ets + 100, participation: participation, ...options});
        }
    }
}

/**
 * finalized changes to employment creation
 * @param {{warnings: {key: string; value: string;}[]; infos: {key: string; value: string;}[]}} messages 
 * @param {Employment} employment 
 * @param {Membership} membership 
 * @param {boolean | undefined} created 
 * @param {object} options 
 * @param {boolean | undefined} options.end This flag will end the flow without saving, and return the employment as is, with flags `newEmploymentCreated` false and `noAction` true
 * @param {boolean | undefined} options.noCommit This flag prevents to save the changes to the database, but {@link MembershipBusiness.reassignNestedParticipations} will always be called on the membership.
 * @param {Remittance | undefined} options.openRemittance 
 * @param {string | undefined} options.eventsSource 
 * @param {boolean | undefined} options.isHiredSourceSystem 
 * @returns {Promise<{ employment: Employment; membership: Membership; newEmploymentCreated?: boolean | undefined; noAction: boolean | undefined;} | void>}
 */
async function saveChanges(messages, employment, membership, created, options = {}) {
    if (options.end) {
        return { messages, employment, membership, newEmploymentCreated: false, noAction: true};
    }
    let creation = created ? 
                        (options?.noCommit ? warningMessages.WILL_NEW : warningMessages.NEW) : 
                        (options?.noCommit ? warningMessages.WILL_NO_CREATE : warningMessages.NO_CREATE);

    addWarn(messages, creation)
    const res = { messages, employment, membership, newEmploymentCreated: created};
    
    if (!options?.noCommit) {
        await MembershipService.update(membership);
        await EmploymentService.update(employment);
    }

    MembershipBusiness.reassignNestedParticipations(membership);
    return res;
}

/**
 * Add the warning in the messages warnings
 * @param {{warnings: {[key: string]: {key: string; value: string;}}; infos: {[key: string]: {key: string; value: string;}}}} messages 
 * @param {{key: string; value: string;} | undefined} warning 
 * @param {string | undefined} customMessage 
 * @returns {{warnings: {[key: string]: {key: string; value: string;}}; infos: {[key: string]: {key: string; value: string;}}}} messages 
 */
function addWarn(messages, warning, customMessage) {
    if (warning) messages.warnings.push({...warning, customMessage});
    return messages;
}

/**
 * Add the info in the messages infos
 * @param {{warnings: {[key: string]: {key: string; value: string;}}; infos: {[key: string]: {key: string; value: string;}}}} messages 
 * @param {{key: string; value: string;} | undefined} info 
 * @param {string | undefined} customMessage 
 * @returns {{warnings: {[key: string]: {key: string; value: string;}}; infos: {[key: string]: {key: string; value: string;}}}} messages 
 */
function addInfo(messages, info, customMessage) {
    if (info) messages.infos.push({...info, customMessage});
    return messages;
}

/**
 * Find the employment that has the latest Fired/Quit event
 * @param {Employment[]} employments 
 * @returns {Employment | undefined}
 */
function getLastFiredEmployment(employments) {
    /** @type {Employment | undefined} */
    let latestFired;
    for (let employment of employments) {
        const event = employment.events.findLast(ev => ev?.status.isFiredQuit());
        if (!latestFired) latestFired = employment;
        else {
            const latest = employment.events.findLast(ev => ev?.status.isFiredQuit());
            if (latest.ets <= event.ets) latestFired = employment;
        }
    }

    return latestFired;
}

export const EmploymentTriggers = {
    create,
    splitEmployment,
    expireEmployment,
    warningMessages,
    infoMessages
}