import SearchResult from '../SearchResult'

/**
 * @extends {SearchResult}
 */
export default class EventResults extends SearchResult {
    /** @type {EventView[]} */
    documents;
    static definitions = {
        documents: { map: true, isListRef: true, ref: require('./EventView'), text: 'Documents' },
    }
}