import { Business } from "../framework/infra"
import { add, moment } from "../framework/utils/helper";
import { YearEndService } from "../services";

/**
 * Business class for the Termination Report
 * Currently all methods are for the individual termination report
 */
export default class TerminationReportBusiness extends Business {

    /** 
     * Contribution buckets for termination export > Contribution sheet
     * @type {['Regular', 'Maternity', 'Long Term', 'Self', 'Voluntary', 'Retro']} */
    static contributionBuckets = ['Regular', 'Maternity', 'Long Term', 'Self', 'Voluntary', 'Retro'];

    /**
     * Gets the array of values for the contribution buckets and fills in 0s for undefined values (if any)
     * @param {Array<number | undefined> | undefined} data array of data
     * @returns {number[]} array of numbers. If data is undefined, returns an array of 0s.
    */
    static getSafeArray = (data) => {
        const emptyYearData = this.contributionBuckets.map(() => 0);
        return data?.map((value) => value ?? 0) ?? emptyYearData;
    }

    /**
     * Gets a safe sum of the data array
     * @param {Array<number | undefined>} data Data to sum
     * @returns the total of the summed amount of the values in the data array or 0 if the data is undefined
     */
    static getSafeSum = (data) => {
        return data?.reduce((total, current) => add(total, current), 0) ?? 0;
    }

    /**
     * Gets the month data for the given year and month index
     * @param {Object[]} monthsYearsData  (see line 139 of EmploymentFinancialTableExport.js)
     * @param {number} year 
     * @param {number | undefined} monthIndex leave this undefined to get the year end data
     * @returns the month data for the given year and month index
     */
    static getMonthData = (monthsYearsData, year, monthIndex) => {
        return monthsYearsData.find(x => Number(x.periodMonth) === Number(monthIndex ?? x.periodYear) && Number(x.periodYear) === Number(year));
    }

    /**
     * Gets the contribution data for the latest year or other years
     * The latest year will show all the contribution buckets, while other years will show the sum of the contributions
     * @param {{Regular: number; Maternity: number; 'Long Term': number; Self: number; Voluntary: number; Retro: number}} contributionBucketValues 
     * @param {{contributionSum: number; adjOrYESum: number}} sumValues 
     * @param {boolean} isLatestYear
     * @returns {number | number[]} an array of numbers representing the contribution buckets or just a number representing the sum of the contributions
     */
    static handleLatestYearVSOtherYears = (contributionBucketValues, sumValues, isLatestYear) => {
        return isLatestYear 
            ? this.getSafeArray(this.contributionBuckets.map(bucket => contributionBucketValues[bucket]))
            : this.getSafeSum([sumValues?.contributionSum, sumValues?.adjOrYESum]);
    } 

    /**
     * Gets the financial summary data for the termination export
     * @param {Employment} employment 
     * @returns {YearEndEmploymentSummary[]} details
     */
    static getFinancialSummaryData = async (employment) => {
        const terminationEvent = employment.getTerminationEvent();
        const terminatedYear = (terminationEvent?.effMoment ?? moment()).year() - 1;

        // Get the financial summary data for the year of termination and the next 2 years
        const numberOfExtraYearsAfterTermination = 2;
        const stopYear = terminatedYear + numberOfExtraYearsAfterTermination;

        let details = [];
        for (var year = terminatedYear; year <= stopYear; year++) {
            let results = await YearEndService.getData({ employment, year: year.toString(), allowNoYearEnds: true, useDefaultSpouse: false });
            
            if (results?.details && results.details.length > 0) {
                details = [...details, ...results.details];
            } else {
                //we want an empty column
                details.push({});
            }
        }
        return details;
    }

    /**
     * Gets the contribution data for every month of the year, for every year in the yearsArray
     * @param {number[]} yearsArray 
     * @param {Object[]} monthsYearsData (see line 139 of EmploymentFinancialTableExport.js)
     */
    static getMonthlyContributionData = (yearsArray, monthsYearsData) => {
        let contributions = []
        // months 1 to 12
        // Current year should show all buckets and prior years only the sum
        for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
            // all years for the current month, ordered by year desc
            const monthData = yearsArray.map(year => this.getMonthData(monthsYearsData, year, monthIndex));
            const monthContributions = monthData.map((data, index) => {
                let contributionColumns = this.handleLatestYearVSOtherYears({
                    Regular: data?.regularContributions,
                    Maternity: data?.maternityContributions,
                    'Long Term': data?.ltdContributions,
                    Self: data?.selfContributions,
                    Voluntary: data?.voluntaryContributions,
                    Retro: data?.retroContributions
                }, { contributionSum: data?.totalEmployeeContributions, adjOrYESum: data?.retroContributions}, index === 0);

                return contributionColumns;
            }).flat();
            contributions.push(monthContributions);
        }
        return contributions;
    }

    /**
     * Gets the year end contribution data for every year in the yearsArray
     * @param {number[]} yearsArray 
     * @param {Object[]} monthsYearsData (see line 139 of EmploymentFinancialTableExport.js)
     */
    static getYearEndContributionData = (yearsArray, monthsYearsData) => {
        // "year end" month: periodYear == periodMonth
        // Current year should show all buckets and prior years only the sum
        const yeMonthData = yearsArray.map(year => this.getMonthData(monthsYearsData, year));
        const yeMonthContributions = yeMonthData.map((data, index) => {
            let contributionColumns = this.handleLatestYearVSOtherYears({
                Regular: data?.regularContributions,
                Maternity: data?.maternityContributions,
                'Long Term': data?.ltdContributions,
                Self: data?.selfContributions,
                Voluntary: data?.voluntaryContributions,
                Retro: data?.retroContributions
            }, { contributionSum: data?.totalEmployeeContributions, adjOrYESum: data?.retroContributions}, index === 0);
            
            return contributionColumns;
        }).flat();
        return yeMonthContributions;
    }


    /**
     * Gets the total contribution data for every year in the yearsArray
     * @param {number[]} yearsArray 
     * @param {Object[]} monthsYearsData (see line 139 of EmploymentFinancialTableExport.js)
     */
    static getTotalContributionData = (yearsArray, monthsYearsData) => {
        // Total row
        // sum for each year, use the ytdContributionsTotal of the "year end" month
        // Current year should show all buckets and prior years only the sum
        const yearsTotals = yearsArray.map((year, index) => {
            // Calculate total retro contributions
            const yearYtdData = monthsYearsData.filter(x => Number(x.periodYear) === Number(year)).reduce((total, data) => add(total, data.retroContributions), 0);
            const ytdData = this.getMonthData(monthsYearsData, year);
            let totalData = this.handleLatestYearVSOtherYears({
                Regular: ytdData?.ytdContributions.reg,
                Maternity: ytdData?.ytdContributions.mat,
                'Long Term': ytdData?.ytdContributions.ltd,
                Self: ytdData?.ytdContributions.slf,
                Voluntary: ytdData?.ytdContributions.vol,
                Retro: yearYtdData
            }, { contributionSum: ytdData?.ytdContributionsTotal, adjOrYESum: yearYtdData}, index === 0);

            return totalData;
        }).flat();
        return yearsTotals;
    }
}