import React, { useEffect, useState } from "react";
import Title from "../../components/ContentHeaderTitle";
import { useParams, useHistory } from "react-router-dom";
import { EmploymentService, MembershipService, RemittanceDetailService } from "../../services";
import Loading from "../../components/containers/Loading";
import { Button, Icon } from "../../framework/controls";
import { Row, Tabs } from "../../framework/containers";
import MemberParticipations from "../member/MemberParticipations";
import EmploymentFinancialTable from "./EmploymentFinancialTable";
import PersonPanel from "../person/PersonPanel";
import EmploymentPanel from "./EmploymentPanel";
import useNotification from "../../hooks/useNotification";
import { Question } from "../../framework/components";

const getTitle = (employment, employerCode) => {
    return <>Employment ({employerCode}) - {employment.person.name} { employment.source ? <Icon tooltip={employment.sourceText} icon='user-circle' className='text-primary' tooltip-right={true} large/> : null}</> 
}

const EmploymentPage = () => {

    const {employerCode, employmentId} = useParams();
    const [membership, setMembership] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeletable, setIsDeletable] = useState(false);
    const { addMessage } = useNotification();
    const [isDeleting, setIsDeleting] = useState(false);

    const history = useHistory();

    const [employerId, personId, partcipationNo] = employmentId.split('_');
    useEffect(() => {

        const findIfDeletable = async (ee, pp) => {
            const employmentDetails = await RemittanceDetailService.getEmploymentRemittancesDetails(ee);
            const participationHasMultipleEmployments = pp.isMER;
            const employmentHasNoRemittanceDetails = !employmentDetails.hasFinancialInfo();
            setIsDeletable(participationHasMultipleEmployments && employmentHasNoRemittanceDetails);
        }

        const loadEmployment = async () => {
            const membership = (await MembershipService.get(personId, { includeEmployments: true })) || null;
            const participation = membership.participations.find(p => p.no === partcipationNo);
            const employment = participation.employments.find(e => e.employer.id === employerId);
            setMembership(membership);
            findIfDeletable(employment, participation)
        }


        loadEmployment(); 
    });

    if (membership === undefined) return <Loading />;

    const participation = membership.participations.find(p => p.no === partcipationNo);
    const employment = participation.employments.find(e => e.employer.id === employerId);

    const finalizeSave = async () => {
        setIsLoading(false);
    }

    const redirectToMembership = () => {
        history.push(`/member/${personId}`);
    }

    const onDelete = async () => {
        setIsDeleting(true);
    }

    const onDeleteConfirm = async (answer) => {
        setIsDeleting(false);
        if (answer) {
            const results = await EmploymentService.deleteEmptyEmployment(employment.keyValue);
            if (results.code === "103") {
                addMessage(`Unable to delete employment`, "danger");
            } else {
                history.push(`/employers`);
            }
        } 
    }

    const loadingContext = {get: isLoading, set: setIsLoading}
    return (
        <div className="d-flex flex-column w-100 h-100 ">
            <Title
                titleText={getTitle(employment, employerCode)} showBackButton
                endButtons={[
                    <Button key="save" text="Membership" className='btn-secondary' onClick={redirectToMembership} />,
                ]}
            />
            <div className="h-90 content-container no-tabs">
                <div className={'transfer-container'}>
                    <PersonPanel 
                        person={membership.person} 
                        style={{height:'100%'}} 
                    />
                    <EmploymentPanel 
                        employment={employment} 
                        participation={participation} 
                        style={{height:'100%'}} 
                        loadingContext={loadingContext}
                        onSave={finalizeSave} 
                    />
                </div>
                <div className="mt15">
                    <Tabs>
                        <Tabs.Tab name='ppHistory' title='Participation History'>
                            { isLoading ? <Loading /> : 
                            <MemberParticipations 
                                key={employment.keyValue}
                                participation={participation} 
                                openEmployment={employment}
                                membership={membership} 
                                onRefreshMember={async () => {
                                    setIsLoading(true);
                                    MembershipService.invalidateCache();
                                    const membership = (await MembershipService.get(personId, { includeEmployments: true })) || null;
                                    setMembership(membership);
                                    setIsLoading(false);
                                }}
                                onSave={finalizeSave}
                                loadingContext={loadingContext}
                            />}
                        </Tabs.Tab>
                        <Tabs.Tab name="contributions" title="Contributions" >
                            <EmploymentFinancialTable key={'contr'} employment={employment} tabName="contributions" />
                        </Tabs.Tab>
                        <Tabs.Tab name="earnings" title="Earnings" >
                            <EmploymentFinancialTable key={'earn'} employment={employment} tabName="earnings" />
                        </Tabs.Tab>
                        <Tabs.Tab name="deemedEarnings" title="Deemed Earnings" >
                            <EmploymentFinancialTable employment={employment} tabName="deemedEarnings" />
                        </Tabs.Tab>
                        <Tabs.Tab name='creditedService' title='Credited Service'>			
                            <EmploymentFinancialTable employment={employment} tabName="creditedService" />
                        </Tabs.Tab>
                    </Tabs>
                </div>
                <div>
                { isDeletable && <Row className='justify-content-end button-row mt20'>
                    <Button key='delete' text={`Delete Employment ${employment.employer.code}`} className='btn-secondary' onClick={onDelete} />
                </Row> }
                { isDeleting && <Question title='Warning. Deleting a person can impact existing financial data and plan participation. Are you sure you want to delete?' onAnswer={onDeleteConfirm} />}
                </div>
            </div>
        </div>
    );
}

export default EmploymentPage;