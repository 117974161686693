import moment from 'moment/moment'
import { Definition, RefMap } from '../../framework/infra'
import { map } from '../../framework/utils/helper'

export default class Employments extends RefMap {

    getLastEmployments() {
        const lastEmps = new this.constructor()
        Object.values(this.reduce((lasts, emp) => {
            lasts[emp.employer.keyValue + emp.participation.membership.keyValue] = emp
            return lasts
        }, {})).forEach(emp => lastEmps.push(emp))
        return lastEmps
    }

    sortChronologically(){
        return this._list.sort((emp1, emp2) => moment(emp1.hiredDate).isAfter(moment(emp2.hiredDate)) ? 1 : -1)
    }

    sortChronologicallyByPPNo() {
        return this._list.sort((emp1, emp2) => emp1.participation.no < emp2.participation.no ? -1 : 1);
    }

    getRelevantInPeriod = (period) => {
        let grouped = this.reduce((group, employment) => {
            if (employment.isEmployedDuringPeriod(period) || employment.participation.canReportEarnsInClosedPP(period)) {
                if (employment.person?.sin && group[employment.person.sin]) group[employment.person.sin].push(employment)
                else if(employment.person?.sin) group[employment.person.sin] = [employment];
            }
            return group;
        }, {})

        return grouped;
    }

    getLatestEmploymentPerPerson() {
        return this.reduce((latestEmpList, currentEmp) => {
            const sortedEmploymentsWithSamePerson = this.getFiltered(emp => emp.person?.sin && currentEmp.person?.sin && emp.person.sin === currentEmp.person.sin).sortChronologicallyByPPNo();
            if (sortedEmploymentsWithSamePerson.length && currentEmp.keyValue === sortedEmploymentsWithSamePerson[sortedEmploymentsWithSamePerson.length-1].keyValue) {
                latestEmpList.push(currentEmp);
            }
            return latestEmpList;
        }, new this.constructor());
    }

    getNonTerminatedForPeriod(period) { 
        return this.getFiltered(emp => emp.eventStatuses.getAt(period.timestampAtPeriodStart).status.isNotTerminated())
    }

    getByPersonSIN() {
        return map(this.all.filter(x => x.participation?.membership?.person?.sin), 'participation.membership.person.sin')
    }

    get startEndDesc() {
        return this.map(emp => emp.startEndDesc).join('\n')
    }
    static definitions = { 
        startEndDesc: { abstract: true, type: Definition.types.STRING , text: 'Employments Hired -> End'}
    }
}
