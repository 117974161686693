import moment from "moment";
import TaskPage from "../../../framework/components/page/TaskPage";
import CalendarValidation from "../../../framework/components/taskValidation/CalendarValidation";
import MultipleChoiceValidation from "../../../framework/components/taskValidation/MultipleChoiceValidation";
import YesNoQuestion from "../../../framework/components/taskValidation/YesNoValidation";
import { toTimestamp, getSpouseEventDetails } from "../../../framework/utils/helper";
import { OnboardingTask } from "./TaskConfig/OnboardingTask";
import config from "../../../utils/config";

// Note that the form's props startDate, instance, onClose, onSave and task are override in MemberParticipationsClass.handleSelect()
// use the flag actionOnSave on the task to perform a custom task on save instead of saving the whole membership, see MemberParticipationsClass.handleTaskCompleted()

export const ParticipationTaskConfig = {
    default: {
        form: <TaskPage />
    },
    over60Def: {
        params: (participation) => { return [{key: 'sixtiethBirthday', value: moment(participation.person.sixtiethBirthday()).format('DD MMM YYYY')}]},
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the member is over age 60
         * - and the participation's last status event is Deferred: `DFR` (Deferred)
         * - and the participation's last event is not `a60sdf` (SDF - Sixty & Still Deferred)
         * - and the membership's other participations are not active (`ACT` "Enrolled") with a last employment in the same jurisdiction as this participation's last employment
         * - and the participation does not have a `retDef` (RFD – Documents have been requested to the employee) event
         */
        validation: (participation) => {
            const participationJurisdiction = participation.employments.last?.employer.plan.jurisdiction;
            const actJurPP = participation.membership.participations.find(pp => pp.keyValue !== participation.keyValue && pp.status.isActive() && pp.employments.last?.employer.plan.jurisdiction === participationJurisdiction);
            const a60sdfEvent = participation.events.last?.code === 'a60sdf';
            const retDefEvent = participation.events.find(ev => ev.code === 'retDef')
            return participation.isDeferredOverAge60() && !a60sdfEvent && !actJurPP && !retDefEvent;
        },
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'retDef', cmt: cmt})} message={'Have pension documents been requested to employee?'}/>,
        formalDescription: " ",
    },
    age60Pen: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the member is over sixty years old
         * - and the participation has a pending pensioner event: `penPnr` (Pending - Pensioner) with a date after the cut off date
         * - and the participation does not have an event `a60Term` (Age60: Proof of birth and confirmation of marital status requested)
         */
        validation: (participation) => {
            const isPendingPensioner = participation.events.find(ev => ev.code === 'penPnr');
            const pendingPensionerIsBeforeCutOffDate = isPendingPensioner ? moment(config.age60PenStartPeriod).valueOf() <= moment(isPendingPensioner.ets).valueOf() : false;
            const age60TermEvent = participation.events.find(ev => ev.code === 'a60Term');
            return participation.person.isOverAge() && pendingPensionerIsBeforeCutOffDate && !age60TermEvent;
        },
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'a60Term', cmt: cmt})} message={'Have proofs of birth and confirmation of marital status been requested?'}/>,
    },
    age60Def: {
        params: (participation) => [{key: 'sixtiethBirthday', value: moment(participation.person.sixtiethBirthday()).format('DD MMM YYYY')}],
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the member is turning 60 in 3 months
         * - and the participation status is Deferred: `DFR` (Deferred)
         */
        validation: (participation) => {
            return participation.person.isTurningAgeInNextMonths(60, 3) && participation.status.isDeferred();
        },
        nextTask : 'rfdtDoc',
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'retDef', cmt: cmt})} message={'Have pension documents been requested to employee?'}/>,
        formalDescription: " ",
    },
    over60Open: {
        params: (participation) => {return [{key: 'sixtiethBirthday', value: moment(participation.person.sixtiethBirthday()).format('DD MMM YYYY')}]},
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation's last employment has a `actPen` (Active & Pensioner - Accruing & receiving pension in different jurisdiction) event
         * - and the membership's participations are not status Deferred: `DFR` (Deferred) with a `a60sdf` (SDF - Sixty & Still Deferred) event
         * - and the member is over age 60, or is turning 60 in the next 3 months, and the participation status is not ignored (ignored when close or NOP or not "pending but not close")
         * 
         * Note: "participation status is not ignored" is:
         * - the participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
         * - and the participation does not have a `isNOP` event: `a60Nop` (Age60: NOP - Active60 Declaration received: continue accruing) or `a60neg` (Age60: NOP - Member not entitled to Pension and work at age 60. Data confirmed by employer.) or `l60Nop` (LTD60: NOP - LTD60 Chose to continue LTD)
         * - and the participation is not "pending but not close": not: participation has a pending event `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner), and participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
         */
        validation: (participation) => {
            const actPenEvent = participation.employments.last?.events.find(ev => ev.code === 'actPen');
            const sdfEvent = participation.membership.participations.find(pp => pp.status.isDeferred() && pp.events.find(ev => ev.code === 'a60sdf'));
            return participation.isTurningAgeInNextMonths(60, 3) && !actPenEvent && !sdfEvent;
        },
    },
    rfdtDoc: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has a `retDef` event (RFD – Documents have been requested to the employee)
         * - and the participation status is Deferred: `DFR` (Deferred)
         */
        validation: (participation) => {
            return participation.events.find(ev => ev.code === 'retDef') && participation.status.isDeferred();
        },
        nextTask: 'rfdPenDt',
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'rfdDoc', cmt: cmt})} message={'Have pension documents been received?'}/>
    },
    rfdPenDt: {
        params: (participation) => {
            return [{key: 'startDate', value: participation.person.isOverAge(60) ? participation.person.sixtiethBirthday(): ''}]
        },
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has a `rfdDoc` event (RFD - Pension documents received)
         */
        validation: (participation) => {
            return participation.events.find(ev => ev.code === 'rfdDoc');
        },
        nextTask: 'rfdSend',
        form: <CalendarValidation 
            dateLabel='Pension Start Date' 
            taskSave={(participation, date, comment) => participation.addEvent({code: 'penDt', ets: toTimestamp(date), cmt: comment})} 
            message='Confirm the Pension Start date'
            isWarning
            definedDate='startDate'
        />    
    },
    rfdSend: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has a `penDt` event (Pension start Date)
         */
        validation: (participation) => participation.events.find(ev => ev.code === 'penDt'),
        /**
         * 
         * @param {Participation} participation 
         * @returns true if the participation has a `penOnp` event (ONP - Pension calculation request sent to Mercer)
         */
        removeValidation: (participation) => { return participation.events.find(ev => ev.code === 'penOnp')},
        form: <YesNoQuestion onYes={(participation, cmt, date) => participation.addEvent({code: 'penOnp', cmt: cmt, ets: toTimestamp(date)})} message={'Has calculation request been sent to actuaries'} isWarning datePicker/>
    },
    rhrdPen: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has an employment
         * - the membership has one participation with status `PEN` (Pensioner) OR pending pensioner: `penPnr` (Pending - Pensioner)
         * - and the participation does not have a `rPenFrms` (Rehiring a Pensioner form has been sent) event or `penEmp` (Pensioner: Deductions ceased age 60) event
         * - and the participation status is not pending or close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled) or pending
         * - and the participation status is not ineligible: `NEG` (Not Eligible)
         */
        validation: (participation) => {
            const emp = participation.employments.last;
            if(emp) {
                const isReceivingPension = participation.membership.participations.find(pp => pp.status.isReceivingPension() || pp.isPendingPensioner());
                const formEvent = participation.events.find(ev => ['rPenFrms', 'penEmp'].includes(ev.code));    
                const isNotEligible = participation.status.isIneligible();

                return isReceivingPension && !formEvent && !participation.isPendingOrClosed() && !isNotEligible && participation.no;
            }
        },
        alwaysShow: true,
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'rPenFrms', cmt: cmt})} message={'Has the Member Declaration: Rehiring a Pensioner form been sent to the employee?'}/> 
    },
    wPenFrm: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has a `rPenFrms` event (Rehiring a Pensioner form has been sent)
         * - and the participation does not have an event `frzPenExp` (Option to freeze pension expired: will not accrue in new employer) or `susPenEli` (Member will suspend pension. Eligible to join) or `susPenNEG` (Pensioner: Rehired - Not Eligible (opted to not suspend pension))
         */
        validation: (participation) => {
            return participation.events.find(ev => ev.code === 'rPenFrms') && !participation.events.find(ev => ['frzPenExp', 'susPenEli', 'susPenNEG'].includes(ev.code));
        },
        alwaysShow: true,
        form: <MultipleChoiceValidation 
            choice={[
                {desc: 'Option to freeze pension expired (more than 30 days)',event: {code: 'frzPenExp'}},
                {desc: 'Declaration received, member will suspend pension', event: {code: 'susPenEli', cmt: 'Advise employer to start deductions. Verify if adjustment is required.'}}, 
                {desc: 'Declaration received, member will NOT suspend pension', event: {code: 'susPenNEG'}}]} 
            header={'Rehiring a pensioner'}
            onYes={(participation, selectEvent) => participation.addEvent(selectEvent)}
            />,
    },
    penExpInf: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has a `frzPenExp` (Option to freeze pension expired: will not accrue in new employer) event
         * - and the participation does not have a `rPenExpl` (Pensioner: Rehired - Not Eligible (offer to suspend pension expired)) event
         */
        validation: (participation) => {
            return participation.events.find(ev => ev.code === 'frzPenExp') && !participation.events.find(ev => ev.code === 'rPenExpl');
        },
        alwaysShow: true,
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'rPenExpl', cmt: cmt})} message={'Has the Rehiring a Pensioner expiration letter been sent to the employee?'}/> 
    },
    onbrd: OnboardingTask,
    cSpouInf: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the participation has a `isSpouseCheck` event: `spouInf` (Verify spouse information). This event is added when the spouse history is updated, and the information of the member's spouse does not match the information of the spouse.
         */
        validation: (participation) => participation.events.find(e => e.config.isSpouseCheck),
        params: (participation) => {
            return [
              {
                key: "spouseName",
                value: () => {
                  const details = getSpouseEventDetails(
                    participation.events.find((e) => e.config.isSpouseCheck)
                  );
                  return details?.message ? ` ${details.message}` : "";
                },
              },
            ];
        },
        alwaysShow: true,
        form: <YesNoQuestion commentField={false} getInstructions={(participation) => {
                    const details = getSpouseEventDetails(
                    participation.events.find((e) => e.config.isSpouseCheck)
                    );
                    const spouseDetails = details?.message ? ` ${details.message}` : "";
                    return `If the spouse${spouseDetails} is not a member, answer Yes.`;
                }
            }
            message={'Has the other member (the spouse) been updated?'}
        />,
        actionOnSave: 'RemoveSpouInfEvents'
    },
    isPen: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if the participation is pending but not close:
         * - participation has a pending event: `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner)
         * - and participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
         */
        validation: (participation) => participation.isPendingClose(),
        formalDescription: "ASH will email data confirmation request.",
    },
    age71Open: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the member is turning 71 in 6 months
         * - and the participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
         */
        validation: (participation) => {
            const isTurning71 = participation.person.isTurningAgeInNextMonths(71, 6);
            const isPPOpen = !participation.status.isClose();
            return isTurning71 && isPPOpen;
        }, 
        params: (participation) => {return [
            {key: 'seventyfirstBirthday', value: moment(participation.person.seventyfirstBirthday()).format('DD MMM YYYY')},
            {key: 'yearAge71', value: moment(participation.person.seventyfirstBirthday()).format('YYYY')},
        ]},
        formalDescription: "Member reaches age 71 on {seventyfirstBirthday}. Must start pension on 01-Dec-{yearAge71}. Stop deductions after November remittance."
    },
    age71Def: {
        /**
         * 
         * @param {Participation} participation 
         * @returns true if:
         * - the member is turning 71 within the next 6 months
         * - and the participation status is deferred `DFR` (Deferred)
         */
        validation: (participation) => {
            const isTurning71 = participation.person.isTurningAgeInNextMonths(71, 6);
            const isPPDeferred = participation.status.isDeferred();
            return isTurning71 && isPPDeferred;
        }, 
        params: (participation) => {return [
            {key: 'seventyfirstBirthday', value: moment(participation.person.seventyfirstBirthday()).format('DD MMM YYYY')},
            {key: 'yearAge71', value: moment(participation.person.seventyfirstBirthday()).format('YYYY')},
        ]},
        alwaysShow: true,
        formalDescription: " ",
    }
    // act60: Active60Task, TBD
}