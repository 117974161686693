import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Row, Title } from "../../containers";
import { toDBDate } from "../../utils/helper";

/**
 * 
 * @param {object} param0 
 * @param {string | undefined} param0.message The title of the modal.
 * @param {boolean | undefined} param0.datePicker Flag to include a date picker. Default: `false`.
 * @param {boolean | undefined} param0.commentField Flag to include a text input to add a comment. Default: `true`.
 * @param {((instance) => string) | undefined} param0.getInstructions Function to get the instructions to show in the modal content above the date picker and comment fields. Default: `undefined`.
 * @param {((instance, comment, date) => Promise<any>) | undefined} param0.onYes Callback function on click on Yes.
 * @param {((instance, comment) => Promise<any>) | undefined} param0.onNo Callback function on click on Yes.
 * @param {((instance) => any) | undefined} param0.onSave Callback function for the parent component to save the modal fields.
 * @param {(() => any) | undefined} param0.onClose Callback function on close.
 * @returns 
 */
const YesNoQuestion = ({instance, onSave, onYes, onNo, onClose, message, isWarning, datePicker, commentField, getInstructions}) => { 
    const [comment, setComment] = useState('');
    const [date, setDate] = useState(toDBDate());

    const handleConfirmEvent = async (confirmed) => {
      if(confirmed){
        if(onYes) await onYes(instance, comment, date)
        onSave(instance);

      }else{
        if(onNo){ 
          await onNo(instance, comment);
          onSave(instance);
        }
        else if(onClose) onClose();
      }
      
    }
   
    return <><Title title={message} onHide={onClose} isWarning={isWarning}/>
        {(typeof getInstructions === 'function') && <div><p>{getInstructions(instance)}</p></div>}
        <div className='input-spacing-2'>
            {datePicker && (
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control type='date' value={date} onChange={(ev) => setDate(ev.target.value)}></Form.Control>
              </Form.Group>
            )}
            {(commentField !== false) && <Form.Group >
              <Form.Label>Comment</Form.Label>
              <Form.Control value={comment} onChange={(newValue) => setComment(newValue.target.value)}/>
            </Form.Group>}
        </div>
        <Row className='mt-4'>
            <div className='col'/>
            <Button key="Yes" onClick={() => handleConfirmEvent(true)}>Yes</Button> 
            <Button key="No" onClick={() => handleConfirmEvent(false)}>No</Button>     
        </Row> 

    </>
 }

 export default YesNoQuestion;