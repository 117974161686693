import TerminationReportBusiness from "../../business/TerminationReportBusiness";
import { EarningType } from "../../entities";
import { Excel } from "../../framework/utils";
import { formatSIN, moment } from "../../framework/utils/helper";
import { YearEndService } from "../../services";

/**
 * Exports the termination report for ONE employment (individual termination report found on the employment page > contribution/earning tab)
 * @param {Employment} employment employment in which the termination report is being generated for
 * @param {RemittanceDetails} remittanceDetails RemittanceDetails aka list of RemittanceDetail entity
 */
export const exportIndividualTerminationReport = async (employment, remittanceDetails) => {
    if (!employment || !remittanceDetails) return;

    // Create the excel file
    const excel = new Excel(`Employee-${employment.person.lastName}${employment.person.firstName}-TerminationReport.xlsx`);

    // Get headers
    const headers = YearEndService.initHeader(
        YearEndService.displayTypes.TERM,
        Excel,
        EarningType.splitEarningsTypesByCategory(employment.employer.getActiveEarningTypes())
    );

    // Add financial summary sheet to excel
    let details = await TerminationReportBusiness.getFinancialSummaryData(employment);
    excel.transpose(excel.addSheet(headers, details, "Financial Summary"));
    
    // Add contributions sheet to excel file
    getContributionsData(employment, remittanceDetails, excel);

    // Add personal info sheet to excel file
    handlePersonalInformationFormatting(employment, details, excel);
    
    // Download the excel file
    excel.download();
}

/**
 * Handles merging cells, formatting and adding borders for the latest year contribution buckets in the contribution sheet
 * @param {number[]} yearsArray 
 * @param {Worksheet} contributionSheet 
 * @param {Excel} excel 
 */
export const handleLatestYearFormatting = (yearsArray, contributionSheet, excel) => {
    const contributionBuckets = TerminationReportBusiness.contributionBuckets;
    // Total retro
    // Find the row with the total contributions and get row index
    const totalRowIndex = contributionSheet._rows.find(x => x.values[1] === 'Total')?.number;

    // Row with year headers
    const yearHeaderRowIndex = contributionSheet._rows.find(x => x.values[1] === 'Month')?.number;
    const bucketsRow = contributionSheet._rows.find(x => x.values[2] === contributionBuckets[0]);
    const latestYearBucketStartCol = bucketsRow._cells.find(cell => cell.value === contributionBuckets[0])?.col; 
    contributionSheet.mergeCells(yearHeaderRowIndex, latestYearBucketStartCol, yearHeaderRowIndex, latestYearBucketStartCol + contributionBuckets.length - 1);

    // Add year header values to the row (other than the latest year)
    const yearRow = contributionSheet.getRow(yearHeaderRowIndex);
    for (let i = 1; i < yearsArray.length; i++) {
        yearRow.getCell(i + contributionBuckets.length + 1).value = yearsArray[i];
        yearRow.getCell(i + contributionBuckets.length + 1).font = { bold: true };
    }

    // Add borders around the contribution buckets for the latest year
    excel.createBorder(contributionSheet, 
        {row: yearHeaderRowIndex, col: latestYearBucketStartCol}, // start cell
        {row: totalRowIndex, col: latestYearBucketStartCol + contributionBuckets.length - 1} // end cell
    );
}

/**
 * Gets the personal information data for the termination export and adds sheet to excel file
 * @param {Employment} employment 
 * @param {YearEndEmploymentSummary[]} details 
 * @param {Excel} excel 
 */
const handlePersonalInformationFormatting = (employment, details, excel) => {
    const lastDetail = details?.filter(x => x.detailKey)?.length ? details[details?.filter(x => x.detailKey)?.length-1] : undefined;
    const flagsData = lastDetail?.isN ? lastDetail : employment.person?.isN ? employment.person : employment.isN ? employment : undefined;
    const personalInfoData = {
        sin: formatSIN(employment.person.sin),
        firstName: employment.person?.firstName,
        lastName: employment.person?.lastName,
        dob: employment.person?.dob,
        address_poBox: employment.person?.address?.poBox,
        address_civic: employment.person?.address?.civic,
        address_city: employment.person?.address?.city,
        address_province: employment.person?.address?.prov,
        address_postalCode: employment.person?.address?.pc,
        address_country: employment.person?.address?.country,
        emails: employment.person?.emails?.all?.map((emailData, i) => ([`Email ${i + 1}`, emailData.email, `Usage ${i + 1}`, emailData.usage, ''])) ?? [],
        phones: employment.person?.phones?.all?.map((phoneData, i) => ([`Phone ${i + 1}`, phoneData.number, `Usage ${i + 1}`, phoneData.usage, ''])) ?? [],
        gender: employment.person?.gender,
        language: employment.person?.lng === 'fr' ? 'French' : employment.person?.lng === 'en' ? 'English' : '',
        native: flagsData?.isN === 'n' ? 'No' : flagsData?.isN === 'y' ? 'Yes' : '',
        cpp_qpp: flagsData?.isCQ === 'n' ? 'No' : flagsData?.isCQ === 'y' ? 'Yes' : '',
        taxPayer: flagsData?.isTP === 'n' ? 'No' : flagsData?.isTP === 'y' ? 'Yes' : '',
    };
    /** Rows of the Personal Info sheet 
     * @type Array<{row: Array<string | number>; isBold?: boolean, mergeCols?: number;, boldCols?: number[]}>*/
    const personalInfoRows = [
        {row: ['Code', employment.employer?.code], boldCols: [0]},
        {row: ['Juridiction', employment.employer?.jurisdictionCode === 'que' ? 'Quebec' : 'Federal' ], boldCols: [0]},
        {row: ['SIN', personalInfoData.sin], boldCols: [0]},
        {row: ['First Name',personalInfoData.firstName], boldCols: [0]},
        {row: ['Last Name', personalInfoData.lastName], boldCols: [0]},
        {row: ['Date of Birth', personalInfoData.dob], boldCols: [0]},
        {row: ['P.O. Box', personalInfoData.address_poBox], boldCols: [0]},
        {row: ['Civic', personalInfoData.address_civic], boldCols: [0]},
        {row: ['City', personalInfoData.address_city], boldCols: [0]},
        {row: ['Province', personalInfoData.address_province], boldCols: [0]},
        {row: ['Postal Code', personalInfoData.address_postalCode], boldCols: [0]},
        {row: ['Country', personalInfoData.address_country], boldCols: [0]},
        {row: [...personalInfoData.emails.flat()], boldCols: [...personalInfoData.emails.map((x, i) => [i*5 + 0, i*5 + 2]).flat()]},
        {row: [...personalInfoData.phones.flat()],  boldCols: [...personalInfoData.phones.map((x, i) => [i*5 + 0, i*5 + 2]).flat()]},
        {row: ['Gender', personalInfoData.gender], boldCols: [0]},
        {row: ['Language', personalInfoData.language], boldCols: [0]},
        {row: ['Native', personalInfoData.native], boldCols: [0]},
        {row: ['CPP/QPP', personalInfoData.cpp_qpp], boldCols: [0]},
        {row: ['Tax Payer', personalInfoData.taxPayer], boldCols: [0]},
    ];

    excel.addPersonalInfoSheet(personalInfoRows);
}

/**
 * Gets the contributions data for the termination export and adds sheet to excel file
 * Every month will be on its separate row. The year end month and the total will be on separate rows as well.
 * For the latest year, the total contributions for every month will be separated into the contribution buckets (Regular, Maternity, Long Term, Self, Voluntary, Retro).
 * 
 * @param {Employment} employment 
 * @param {RemittanceDetails} remittanceDetails 
 * @param {Excel} excel 
 */
const getContributionsData = (employment, remittanceDetails, excel) => {
    /**
     * @type Array<{totalEmployeeContributions: number, regularContributions: number, maternityContributions: number, ltdContributions: number, selfContributions: number, voluntaryContributions: number, retroContributions: number, periodText: string, periodYear: string, periodMonth: string, ytdContributionsTotal: number, ytdContributions: Contributions}>
     */
    const monthsYearsData = remittanceDetails?.map(x => ({
        totalEmployeeContributions: x.totalEmployeeContributions,
        regularContributions: x.sumContributions.reg,
        maternityContributions: x.sumContributions.mat,
        ltdContributions: x.sumContributions.ltd,
        selfContributions: x.sumContributions.slf,
        voluntaryContributions: x.sumContributions.vol,
        retroContributions: x.retroContributionAdjustments.totalDetail,
        periodText: x.periodText,
        periodYear: x.period?.year,
        periodMonth: x.period?.month,
        ytdContributionsTotal: x.ytdContributions.total,
        ytdContributions: x.ytdContributions,
    }));
    const contributionBuckets = TerminationReportBusiness.contributionBuckets;
    const yearsSet = new Set();
    const currentYear = new Date().getFullYear();
    // ensure there are all years between currentYear and oldest year
    for (let year = Math.min(...monthsYearsData.filter(x => x.periodYear).map(x => Number(x.periodYear))); year <= currentYear; year++) {
        yearsSet.add(year);
    }

    /** Rows of the Contributions sheet 
     * @type Array<{row: Array<string | number>; isBold?: boolean, mergeCols?: number;, boldCols?: number[]}>*/
    const contributionRows = [];
    const yearsArray = Array.from(yearsSet);
    yearsArray.sort().reverse();

    // merge the title (cols: Labels cols + all years cols). 0-based index
    const lastColIndex = 1 + yearsSet.size - 1;
    contributionRows.push({row: ["Total Contribution Summary"], isBold: true, mergeCols: lastColIndex + 1}); // 1
    contributionRows.push({row: ["Employer:", `${employment.employer?.code || ''} - ${employment.employer?.name || ''}`], isBold: true}); // 2
    contributionRows.push({row: ["Period:", `${employment.participation?.joinDt ? moment(employment.participation?.joinDt).format('DD MMM YYYY') : ''} - ${moment(new Date()).format('DD MMM YYYY')}`], isBold: true}); // 3
    contributionRows.push({row: ["Employee name:", employment.person.name], isBold: false}); // 4
    contributionRows.push({row: ["SIN:", formatSIN(employment.person.sin)], isBold: false}); // 5
    contributionRows.push({row: ["Generated Date:", moment(new Date()).format('DD MMM YYYY')], isBold: false}); // 6
    contributionRows.push({row: [], isBold: false}); // 7
    contributionRows.push({row: ['Month', yearsArray[0]], isBold: true}); // 8
    contributionRows.push({row: ['', ...contributionBuckets, ...yearsArray.slice(1).map(year => "Total Contributions")], isBold: true}); // 9

    // Get the monthly contribution row (months 1 to 12 aka Jan to Dec)
    const allMonthContributions = TerminationReportBusiness.getMonthlyContributionData(yearsArray, monthsYearsData);
    allMonthContributions.forEach((monthContributions, index) => { 
        const monthIndex = index + 1;
        contributionRows.push({row: [`${monthIndex}`, ...monthContributions], isBold: false});
    });

    // Get the year end contribution row
    const yeMonthContributions = TerminationReportBusiness.getYearEndContributionData(yearsArray, monthsYearsData);
    contributionRows.push({row: ['YE', ...yeMonthContributions], isBold: false});
    contributionRows.push({row: [], isBold: false});

    // Get the total contribution row
    const yearsTotals = TerminationReportBusiness.getTotalContributionData(yearsArray, monthsYearsData);
    contributionRows.push({row: ['Total', ...yearsTotals], isBold: true});

    // Add contribution sheet to excel file
    let contributionSheet = excel.addContributionsSheet(contributionRows);

    // Handle formatting for the latest year contribution buckets
    handleLatestYearFormatting(yearsArray, contributionSheet, excel);
}