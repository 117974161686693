import { Definition, Ref}  from '../../framework/infra'

/**
 * @extends {Ref}
 */
export default class SearchResult extends Ref {
    /** @type {number} */
    total;
    /** @type {number} */
    page;
    /** @type {number} */
    pageSize;
    /** @type {number} */
    totalPages;
    /** @type {string} */
    scrollId;

    static definitions = {
        total: { type: Definition.types.NUMBER, text: 'Total' },
        page: { type: Definition.types.NUMBER, text: 'Page' },
        pageSize: { type: Definition.types.NUMBER, text: 'Page Size' },
        totalPages: { type: Definition.types.NUMBER, text: 'Total Pages' },
        scrollId: { type: Definition.types.STRING, text: 'Scroll ID' },
    }
}