import { round } from '../../../../../framework/utils/helper'

/**
 * default options for amount/number columns in export
 * 
 * - format: rounding value (2 decimals)
 */
export const defaultExportNumberConfig = {
    format: (val, instance) => val ? round(val) : val,
}
/**
 * default options for string/cmt columns in export
 * 
 * - wrapCsvValueInQuotes: true
 */
export const defaultExportStringConfig = {
    wrapCsvValueInQuotes: true
}