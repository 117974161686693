import { Definition } from '../../../../framework/infra'
import { Excel, Period } from '../../../../framework/utils'
import { sort, round, add, moment, sum } from '../../../../framework/utils/helper'
import { formatDateYYYYMMDD, formatTabulatorSafeDataInstance } from '../../../../framework/utils/formating'
import { AdjustmentService, RemittanceService, EmployerService } from '../../../../services'
import { Remittance, Payment, AccountDistribution } from '../../../../entities'
import ReportLayoutTabs from '../../../../framework/components/page/ReportLayoutTabs'
import { RemittanceBusiness } from '../../../../business'
import config from "../../../../utils/config"
import { chunk, sortBy, merge as _merge} from 'lodash';
import {defaultExportNumberConfig, defaultExportStringConfig} from './util/ReportsUtil'

/**
 * Processing Log report
 */
export default class RemittancesLogReport extends ReportLayoutTabs {
    reportDesc = 'Processing Log'
    /** Excel file name */
    get reportExportLabel() {
        const employerFilter = this.state.filtersInstance?.["employer"];
        const employerFilterLabel = employerFilter?.code
          ? `_${employerFilter.code}`
          : "";
        const fromParamLabel = this.state.queryParamsInstance?.from?.year
          ? `_${this.state.queryParamsInstance.from.year}`
          : "";
        return `Statement${employerFilterLabel}${fromParamLabel}`;
    }
    entity = Remittance
    subEntity = AccountDistribution
    headers = [
        'period', 'employer.code', 'employer.plan.jurisdiction', 
        'importedDate', 'regular', 'maternity', 'longTerm', 'self',
        'eeAdjustments', 'totalEeContribs',
        'erContribs', 'totalErAdjustmentsNoCredit',
        'totalEmployerErContributions',
        'voluntary', 'totalCurrentServiceContribs',
        'solvencyDeficit', 'interestSum',
        'creditUsedNegative',
        'prevTotalOwing',
        'totalOwingPrePaymentSum', 'totalPaymentsNegative', 'totalOwingSum', 'totalRemainingBalance',
        'balance'
    ]
    headerProps = {
        // See also headerProps in AuditSummaryReport
        'period': {minWidth: 92},
        'employer.code': {minWidth: 78},
        'employer.plan.jurisdiction': {minWidth: 84},
        'importedDate': {minWidth: 152},
        'regular': {overrideText: 'A\nDeductions [EE]', },
        'maternity': {overrideText: 'B\nMaternity [EE]'},
        'longTerm': {overrideText: 'C\nLong-Term [EE]'},
        'self': {overrideText: 'D\nSelf [EE]'},
        'eeAdjustments': {overrideText: 'E\nTotal EE Adjustments'},
        'totalEeContribs': {overrideText: 'F\n{A+B+C+D+E}\nTotal Employee [EE] Contributions'},
        'erContribs': {overrideText: 'G\nEmployer Contributions'},
        'totalErAdjustmentsNoCredit': {overrideText: 'H\nTotal ER Adjustments'},
        'totalEmployerErContributions': {overrideText: 'J\n{G+H}\nTotal Employer [ER] Contributions'},
        'voluntary': {overrideText: 'K\nVoluntary'},
        'totalCurrentServiceContribs': {overrideText: 'L\n{F+J+K}\nTotal Current Service Contributions'},
        'solvencyDeficit': {overrideText: 'M\nTotal Solvency Contributions'},
        'interestSum': {overrideText: 'N\nTotal Interest on Late Payment'},
        'creditUsedNegative': {overrideText: 'O\nER Holiday Offset\n{J + N not owing}'},
        'prevTotalOwing': {overrideText: 'P\nPrevious Balance'},
        'totalOwingPrePaymentSum': {overrideText: 'R\n{L+M+N+O+P}\nTotal Owing (pre-payment)'},
        'totalPaymentsNegative': {overrideText: 'Q\nTotal Payments'},
        'totalOwingSum': {overrideText: 'R\n{L+M+N+O+P+Q}\nTotal Owing (post-payment)'},
        'totalRemainingBalance': {overrideText: 'S\nCarrying Balance of Holiday Credit to Offset ER Portions'},
        'balance': {overrideText: 'T\n{R+S}\nBalance'},
    }

    ER_PL_Adjustments_headers = [
        'period', 'employer.code', 'employer.plan.jurisdiction',
        'eeCurYearAdjustments', 'totalPriorEeAdjustments', 'eeAdjustments',
        'erCurYearAdjustmentsNoCredit', 'totalPriorErAdjustmentsManual',
        'priorIntAdjustments', 'totalErAdjustmentsNoCredit',
        'interest', 'intLatePaymentAdjustments', 'interestSum',
        'solvency', 'solAdjustments', 'solAdjusted',
        'erContribs', 'creditUsedNegative',
        'principalMandatoryEmployerHoliday', 'accumulatedMandatoryOffsetCreditUsed',
        'currentServiceAdjustment', 'accumulatedCurrentServiceCreditUsed',
        'totalRemainingBalance', 'curYearAdjCmt', 'erAdjustmentCmtER',
        'solAdjustmentsCmt', 'intAdjustmentsCmt',
    ]
    ER_PL_Adjustments_headerProps = {
        'period': this.headerProps['period'],
        'employer.code': this.headerProps['employer.code'],
        'employer.plan.jurisdiction': this.headerProps['employer.plan.jurisdiction'],
        'eeCurYearAdjustments': {overrideText: '1\nMember Contribution Adjustment -\nEE\n(Current Year)'},
        'totalPriorEeAdjustments': {overrideText: '2\nMember Contribution Adjustment - EE\n(Prior Years)'},
        'eeAdjustments': {overrideText: 'E\nTotal Member Contribution Adjustment - EE\n{1+2}'},
        'erCurYearAdjustmentsNoCredit': {overrideText: '3\nMember Contribution Adjustment - ER\n(Current Year)'},
        'totalPriorErAdjustmentsManual': {overrideText: '4\nMember Contribution Adjustment - ER\n(Prior Years)'},
        'priorIntAdjustments': {overrideText: '5\nMember Contribution Adjustment - Interest\n(Prior Years)'},
        'totalErAdjustmentsNoCredit': {overrideText: 'H\nTotal Member Contribution Adjustment - ER\n{3+4+5}'},
        'intLatePaymentAdjustments': {overrideText: '6\nInterest on Late Payment\nAdjustment'},
        'interest': {overrideText:'66\nInterest on Late Payment'},
        'interestSum': {overrideText:'N\nTotal Interest on Late Payment\n{6 + 66}'},
        'solvency': {overrideText: '7\nSolvency'},
        'solAdjustments': {overrideText: '8\nSolvency Adjustments'},
        'solAdjusted': {overrideText: 'M\nTotal Solvency Contributions\n{7 + 8}'},
        'erContribs': {overrideText: 'G\nEmployer Contributions\n{A + B + C + D} x ER Contrib Rate'},
        'creditUsedNegative': {overrideText: 'S\nEmployer Contributions used to offset credit\nH + N + G (if applicable)'},
        principalMandatoryEmployerHoliday: {overrideText: '9\nPrincipal Mandatory Employer Holiday'},
        accumulatedMandatoryOffsetCreditUsed: {overrideText: '10\nAccumulated Mandatory Offset Credit Used'},
        currentServiceAdjustment: {overrideText: '11\nCurrent Service Adjustment'},
        accumulatedCurrentServiceCreditUsed: {overrideText: '12\nAccumulated Current Service Credit Used'},
        totalRemainingBalance: {overrideText: 'W\nTotal Remaining Balance\n{9 -10 +11 -12}'},
        'curYearAdjCmt': {overrideText: 'Current year Adjustment Comments'},
        'erAdjustmentCmtER': {overrideText: 'ER Adjustment Comment'},
        'solAdjustmentsCmt': {overrideText: 'Solvency Adjustments Comments'},
        'intAdjustmentsCmt': {overrideText: 'Interest Adjustments Comments'},
    }
    RBC_Payment_and_Contributions_headers = ['period', 'employer.code', 
        'rcvDate', 'distribution', 'no',
        // cmt: MUST ONLY APPEAR after 2023-1-1 - since the distributions prior were not ok, therefore if we run a report for a year prior, that column will not appear
        'cmt', 'distribution.longDescByPeriod'
    ]
    RBC_Payment_and_Contributions_headerProps = {
        'period': {...this.headerProps['period'], overrideText: 'Period'},
        'employer.code': this.headerProps['employer.code'],
        'distribution': {title: 'Total Payment', overrideText: 'Total Payment'}
    }

    /**
     * Config for the tabs (to render the tables and to export the data)
     * 
     * For each tab config:
     * - tabName: name/key of the tab. It will be the suffix of the exported file name.
     * - tabTitle: title of the tab.
     * - tableHeaders: headers of the table (columns in Tabulator).
     * - tableHeadersProps: props for the table headers.
     * - exportColumns: list of columns to export. Includes the config of each exported column. Note: an export column should not be a group header.
     */
    tabsConfig=[
        {
            tabName: "ER_Processing_log", tabTitle: "ER Processing log",
            tableHeaders: [...this.headers], tableHeadersProps: {...this.headerProps},
            exportColumns: _merge({...this.headerProps}, {
                'period': {
                    overrideText: 'Period',
                    format: (val, instance) => {
                        return val?.value || val;
                    },
                },
                'importedDate': {
                    format: (val, instance) => formatDateYYYYMMDD(val) || val,
                },
                'regular': {...defaultExportNumberConfig},
                'maternity': {...defaultExportNumberConfig},
                'longTerm': {...defaultExportNumberConfig},
                'self': {...defaultExportNumberConfig},
                'eeAdjustments': {...defaultExportNumberConfig},
                'totalEeContribs': {...defaultExportNumberConfig},
                'erContribs': {...defaultExportNumberConfig},
                'totalErAdjustmentsNoCredit': {...defaultExportNumberConfig},
                'totalEmployerErContributions': {...defaultExportNumberConfig},
                'voluntary': {...defaultExportNumberConfig},
                'totalCurrentServiceContribs': {...defaultExportNumberConfig},
                'solvencyDeficit': {...defaultExportNumberConfig},
                'interestSum': {...defaultExportNumberConfig},
                'creditUsedNegative': {...defaultExportNumberConfig},
                'prevTotalOwing': {...defaultExportNumberConfig},
                'totalOwingPrePaymentSum': {...defaultExportNumberConfig},
                'totalPaymentsNegative': {...defaultExportNumberConfig},
                'totalOwingSum': {...defaultExportNumberConfig},
                'totalRemainingBalance': {...defaultExportNumberConfig},
                'balance': {...defaultExportNumberConfig}
            })
        },
        {
            tabName: "ER_PL_Adjustments", tabTitle: "ER PL Adjustments",
            tableHeaders: [...this.ER_PL_Adjustments_headers],
            tableHeadersProps: {...this.ER_PL_Adjustments_headerProps},
            exportColumns: _merge({...this.ER_PL_Adjustments_headerProps}, {
                'period': {
                    format: (val, instance) => (val?.value || val),
                    overrideText: this.ER_PL_Adjustments_headerProps['period']?.overrideText || 'Period'
                },
                'eeCurYearAdjustments': {...defaultExportNumberConfig},
                'totalPriorEeAdjustments': {...defaultExportNumberConfig},
                'eeAdjustments': {...defaultExportNumberConfig},
                'erCurYearAdjustmentsNoCredit': {...defaultExportNumberConfig},
                'totalPriorErAdjustmentsManual': {...defaultExportNumberConfig},
                'priorIntAdjustments': {...defaultExportNumberConfig},
                'totalErAdjustmentsNoCredit': {...defaultExportNumberConfig},
                'intLatePaymentAdjustments': {...defaultExportNumberConfig},
                'interest': {...defaultExportNumberConfig},
                'interestSum': {...defaultExportNumberConfig},
                'solvency': {...defaultExportNumberConfig},
                'solAdjustments': {...defaultExportNumberConfig},
                'solAdjusted': {...defaultExportNumberConfig},
                'erContribs': {...defaultExportNumberConfig},
                'creditUsedNegative': {...defaultExportNumberConfig},
                'principalMandatoryEmployerHoliday': {...defaultExportNumberConfig},
                'accumulatedMandatoryOffsetCreditUsed': {...defaultExportNumberConfig},
                'currentServiceAdjustment': {...defaultExportNumberConfig},
                'accumulatedCurrentServiceCreditUsed': {...defaultExportNumberConfig},
                'totalRemainingBalance': {...defaultExportNumberConfig},
                'curYearAdjCmt': { ...defaultExportStringConfig},
                'erAdjustmentCmtER': { ...defaultExportStringConfig},
                'solAdjustmentsCmt': { ...defaultExportStringConfig},
                'intAdjustmentsCmt': { ...defaultExportStringConfig},
            }),
        },
        {
            tabName: "RBC_Payment_and_Contributions", tabTitle: "RBC Payment and Contributions",
            tableHeaders: [...this.RBC_Payment_and_Contributions_headers],
            tabGroupHeaders: ['distribution'],
            tabSubHeaders: ['eeAmount', 'volAmount', 'erAmount', 'solAmount', 'total'],
            tableHeadersProps: _merge({...this.RBC_Payment_and_Contributions_headerProps}, {
                // FIXME, should use the text from the definition in /src/entities/financial/trustee/AccountDistribution.js
                'distribution.longDescByPeriod': {overrideText: 'Distribution'},
                'distribution.eeAmount': {overrideText: 'Employee'},
                'distribution.volAmount': {overrideText: 'Voluntary'},
                'distribution.erAmount': {overrideText: 'Employer'},
                'distribution.solAmount': {overrideText: 'Solvency'},
                'distribution.total': {overrideText: 'Total'},
                }
            ),
            exportColumns: {
                'period': {...this.RBC_Payment_and_Contributions_headerProps['period'], format: (val, instance) => (val?.value || val),},
                'employer.code': {...this.RBC_Payment_and_Contributions_headerProps['employer.code']},
                'distribution.eeAmount': {...defaultExportNumberConfig},
                'distribution.volAmount': {...defaultExportNumberConfig},
                'distribution.erAmount': {...defaultExportNumberConfig},
                'distribution.solAmount': {...defaultExportNumberConfig},
                'distribution.total': {...defaultExportNumberConfig},
                'rcvDate': {format: (val, instance) => formatDateYYYYMMDD(val) || val,},
                'no': {...defaultExportStringConfig},
                'cmt': {...defaultExportStringConfig},
                'distribution.longDescByPeriod': {...defaultExportStringConfig},
            },
        },
    ]

    sheetsConfig = {
        // 1st sheet (ER Processing log):
        ER_Processing_log: {
            sheetName: "ER Processing log",
            headers: [...this.headers],
            headerProps: {...this.headerProps}
        },
        // 2nd sheet (ER PL Adjustments):
        ER_PL_Adjustments: {
            sheetName: "ER PL Adjustments",
            headers: [...this.ER_PL_Adjustments_headers],
            headerProps: {...this.ER_PL_Adjustments_headerProps}
        },
        // 3rd sheet (RBC Payment and Contributions):
        RBC_Payment_and_Contributions: {
            sheetName: "RBC Payment and Contributions",
            headers: [...this.RBC_Payment_and_Contributions_headers],
            groupHeaders: ['distribution'],
            subHeaders: ['eeAmount', 'volAmount', 'erAmount', 'solAmount', 'total'],
            headerProps: {...this.RBC_Payment_and_Contributions_headerProps}
        }
    };

    tableSort = 'employer.code'
    filters = {
        'employer.plan.jurisdiction': {
            nullable: true, 
            initialValue: this.props.params && this.props.params.employer.plan.jurisdiction
        },
        'employer': {
            sortBy: 'code', 
            display: 'longDesc', 
            nullable: true, initialValue: this.props.params && this.props.params.employer
        },
    }
    params = {
        from: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "From Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                ),
            }
        },
        to: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "To Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                )
            }
        }
    }

    handleExport(data) {
        const excel = new Excel(this.reportExportLabel)
        
        if (this.tableSort) data = sort(data, this.tableSort)

        for (const [key, sheetConfig] of Object.entries(this.sheetsConfig)) {
            if(key === 'RBC_Payment_and_Contributions') {
                const thisData = this.filterDataWithFiltersInstances([], 'RBC_Payment_and_Contributions');
                const includeCommentColumn = thisData.some(x => (x.rcvDate === config.cutoff2023 || moment(x.rcvDate).isAfter(moment(config.cutoff2023))));
                // cmt: MUST ONLY APPEAR after 2023-1-1 - since the distributions prior were not ok, therefore if we run a report for a year prior, that column will not appear
                const headers = new Excel.Headers(Payment, sheetConfig.headers.filter(x => !(x === 'cmt' && !includeCommentColumn)));
                const groupHeaders = sheetConfig.groupHeaders ?? [];
                if (groupHeaders.length) {
                    groupHeaders.forEach(path => {
                        const header = headers[path] = new Excel.Header(AccountDistribution, "")
                        const columns = sheetConfig.subHeaders.reduce((cols, sub) => {
                            cols.push(path + `.${sub}`)
                            return cols
                        }, [])
                        header.headers = new Excel.Headers(AccountDistribution, columns) 
                    })
                }
                if (sheetConfig.headerProps) this.applyHeaderProps(headers, sheetConfig.headerProps);
                const ignoreColumnOnRow = (col, row) => {return Boolean(col.name === 'cmt') && !(row.rcvDate === config.cutoff2023 || moment(row.rcvDate).isAfter(moment(config.cutoff2023))); }
                excel.addSheet(headers, thisData, sheetConfig.sheetName, true, true, ignoreColumnOnRow);
            } else {
                const headers = new Excel.Headers(this.entity, sheetConfig.headers);
                if (sheetConfig.headerProps) {
                    this.applyHeaderProps(headers, sheetConfig.headerProps);
                }
                excel.addSheet(headers, data, sheetConfig.sheetName, true, true);
            }
        }

        return excel.download()
    }
    
    applyHeaderProps(headers, headerProps) {
        Object.getOwnPropertyNames(headerProps).forEach(headerProp => {
            Object.getOwnPropertyNames(headerProps[headerProp]).forEach(prop => {
                if(prop !== 'overrideText') {
                    if(!headers[headerProp]) headers[headerProp] = {};
                    headers[headerProp][prop] = headerProps[headerProp][prop];
                }
            });
            if(headerProps[headerProp]['overrideText']) {
                if(!headers[headerProp]) headers[headerProp] = {};
                headers[headerProp]['title'] = headerProps[headerProp]['overrideText'];
            }
        })
        return headers
    }


    /**
     * @override
     * @param {{to: Period; from: Period}} queryParams 
     */
    async execQuery(queryParams) {
        const employers = await EmployerService.getAll();
        const employerIds = employers.all?.map(emp => emp.id) ?? [];
        const remittancesFromEmployers = [];
        const adjustmentsFromEmployers = [];
        const batchSize = 15;
        const employerIdsBatches = chunk(employerIds, batchSize); // [["", "", ...]]
        for (let index = 0; index < employerIdsBatches.length; index++) {
            const employerIdsBatch = employerIdsBatches[index];
            const empRems = await Promise.all(employerIdsBatch.map(empId => RemittanceService.getEmployerRemittances(empId).then(list => list?.all ?? []))).then(employerRemittances => employerRemittances.flat?.() ?? employerRemittances ?? []);
            remittancesFromEmployers.push(...(empRems?.all ?? empRems ?? []));

            const empAdjs = await Promise.all(employerIdsBatch.map(empId => AdjustmentService.getAdjustmentsForEmployer(empId).then(list => list?.all ?? []))).then(employerAdjustments => employerAdjustments.flat?.() ?? employerAdjustments ?? []);
            adjustmentsFromEmployers.push(...(empAdjs?.all ?? empAdjs ?? []));
        }
        const remittances = remittancesFromEmployers;
        const adjustments = adjustmentsFromEmployers;

        const remittancesWithAdjustments = remittances.map(remittance => {
            
            const adjustmentsForRemittance = adjustments.filter(adjustment => 
                adjustment.remittance.keyValue === remittance.keyValue
            );
            remittance.adjustments = adjustmentsForRemittance;
            remittance.priorYearsAdjustments = [];
            remittance.curYearAdjustments = [];

            return remittance;
        }).map((remittance, index, remittanceList) => {
            if (!remittance.validated) {
                const employerRemittances = remittanceList.filter(rem => 
                    rem.employer.keyValue === remittance.employer.keyValue
                );
                RemittanceBusiness.refreshBalances(employerRemittances);
            }
            return remittance;
        });  

        const filteredRemittancesWithAdjustments = remittancesWithAdjustments.filter(rem => 
            rem.period.isSameOrBefore(queryParams.to) && rem.period.isSameOrAfter(queryParams.from)
        ).sort((a,b)=> a.period.isAfter(b.period) ? 1 : -1);
        filteredRemittancesWithAdjustments.forEach(remWithAdjs => {
            if(!remWithAdjs.priorYearsAdjustments) {
                remWithAdjs.priorYearsAdjustments = [];
            }
            if(!remWithAdjs.curYearAdjustments) {
                remWithAdjs.curYearAdjustments = [];
            }
            remWithAdjs.adjustments.forEach(adj => {
                if(remWithAdjs.period?.text === adj.period?.text
                    && adj.targetPeriod?.year && remWithAdjs.period?.year && Number(adj.targetPeriod.year) < Number(remWithAdjs.period.year)){
                        remWithAdjs.priorYearsAdjustments.push(adj);
                } else if(adj.targetPeriod?.year && remWithAdjs.period?.year && Number(adj.targetPeriod.year) === Number(remWithAdjs.period.year)){
                    remWithAdjs.curYearAdjustments.push(adj);
                }
            });
        });

        filteredRemittancesWithAdjustments.forEach(remWithAdj => {
            const employerOtherAdjs = remittancesWithAdjustments.filter(rem => rem.employer.code === remWithAdj.employer.code).map(rem => rem.adjustments).flat();

            const missingCredits = AdjustmentService.getMissingStartCreditsForAllTypes(remWithAdj.period, employerOtherAdjs, false);
            const missingReversingCredits = AdjustmentService.getMissingReversingCreditsForAllTypes(remWithAdj.period, employerOtherAdjs);
            const missingReversingUsedCredits =  AdjustmentService.getMissingReversingUsedCreditsForAllTypes(remWithAdj.period, employerOtherAdjs);
            // cannot use getter in Remittance class because it needs other remittances to be calculated
            // see related calculation in getTotal() in src/views/financial/reporting/PDFAdjustment.jsx
            const adjustmentsCONT = [...remWithAdj.adjustments, ...missingCredits, ...missingReversingCredits, ...missingReversingUsedCredits].filter(adj => adj.category === "CONT");
            
            // Principal Mandatory Employer Holiday: 
            // Total amount in “Addendum to statement ref“ in PDF (for adjustments of type MCHE[R]: [Used] Mandatory Employer Contribution Holiday)
            // sum of all start credit up to the adj's period.
            // Rule: get all the MECH start credits.
            const adjustmentsMECH = employerOtherAdjs.filter(x => x.type?.key === 'MECH' && x.period.isSameOrBefore(remWithAdj.period) && !x.period.isBefore(Period.getTotalOwingStartPeriod()));
            const adjustmentsForMECH_total =  round(adjustmentsMECH.map(adjustment => {
                return adjustment.total;
            }).reduce((total, adjTotal) => add(adjTotal, total), 0));
            remWithAdj.principalMandatoryEmployerHoliday = adjustmentsForMECH_total;

            // Accumulated Mandatory Offset Credit Used: 
            // Cumulative Employer Contributions used to offset credit in “Addendum to statement ref“ in PDF (for adjustments of type MECHR: Used Mandatory Employer Contribution Holiday)
            // cannot use getter in Remittance class because it needs other remittances to be calculated
            // see related calculation in getCreditLayout() in src/views/financial/reporting/PDFAdjustment.jsx
            // add - on the number because they're positive amounts but must be shown as credit
            const adjustmentsUsedMECHR = employerOtherAdjs.filter(x => x.type?.key === 'MECHR' && x.period.isSameOrBefore(remWithAdj.period));
            const adjustmentsCancelUsedMECHC = employerOtherAdjs.filter(x => x.type?.key === 'MECHC' && x.period.isSameOrBefore(remWithAdj.period));
            remWithAdj.accumulatedMandatoryOffsetCreditUsed = -(round([...adjustmentsUsedMECHR, ...adjustmentsCancelUsedMECHC].map(adjustment => {
                return adjustment.total;
            }).reduce((total, adjTotal) => add(adjTotal, total), 0)));

            
            // Current Service Adjustment: 
            // Total amount in “Addendum to statement ref“ in PDF (for adjustments of type RCR: Retro Employer Contribution Rate Change)
            // cannot use getter in Remittance class because it needs other remittances to be calculated
            const adjustmentsRCR = employerOtherAdjs.filter(x => x.type?.key === 'RCR' && x.period.isSameOrBefore(remWithAdj.period) && !x.period.isBefore(Period.getTotalOwingStartPeriod()));
            remWithAdj.currentServiceAdjustment = round(adjustmentsRCR.map(adjustment => {
                return adjustment.total;
            }).reduce((total, adjTotal) => add(adjTotal, total), 0));

            // Accumulated Current Service Credit Used: 
            // Cumulative Employer Contributions used to offset credit in “Addendum to statement ref“ in PDF (for adjustments of type RCRR: Retro Employer Contribution Rate Change)
            // cannot use getter in Remittance class because it needs other remittances to be calculated
            const adjustmentsUsedRCRR = employerOtherAdjs.filter(x => x.type?.key === 'RCRR' && x.period.isSameOrBefore(remWithAdj.period));
            const adjustmentsCancelUsedRCRC = employerOtherAdjs.filter(x => x.type?.key === 'RCRC' && x.period.isSameOrBefore(remWithAdj.period));
            // add - on the number because they're positive amounts but must be shown as credit
            remWithAdj.accumulatedCurrentServiceCreditUsed = -(round([...adjustmentsUsedRCRR, ...adjustmentsCancelUsedRCRC].map(adjustment => {
                return adjustment.total;
            }).reduce((total, adjTotal) => add(adjTotal, total), 0)));

            const creditTotal = employerOtherAdjs
                .filter(adj => adj.period.isSame(remWithAdj.period) && adj.type.config.isCredit)
                .reduce((total, adj) => add(adj.total, total), 0);
            // cannot use getter in Remittance class because it needs other remittances and adjustments to be calculated
            remWithAdj.totalEmployerErContributions = round(remWithAdj.erAdjustedContribs + remWithAdj.intAdjustments - creditTotal);

            remWithAdj.totalCurrentServiceContribs = remWithAdj.eeAdjustedContribs 
            + (remWithAdj.erAdjustedContribs + remWithAdj.intAdjustments - creditTotal)
            + remWithAdj.voluntary;
        });

        const remittancePayments = filteredRemittancesWithAdjustments.map(rem => rem.payments)
        const payments = remittancePayments.reduce((paymentList, payments) => {
            payments.forEach(payment => paymentList.push(payment))
            return paymentList
        }, []);

        const filteredPayments =  payments.filter(payment => 
            payment.period.isSameOrBefore(queryParams.to) && payment.period.isSameOrAfter(queryParams.from)
        );
        //sort by employer code and then by date/period
        const sortedPayments = sortBy(filteredPayments, ['employer.code', 'rcvDate']);

        const filteredRemittancesWithAdjustments_asObjects = filteredRemittancesWithAdjustments.map(instance => {
            return _merge({}, 
                formatTabulatorSafeDataInstance(instance, this.headers),
                formatTabulatorSafeDataInstance(instance, this.ER_PL_Adjustments_headers),
            );
        });
        const sortedPayments_asObjects = sortedPayments.map(instance => {
            return formatTabulatorSafeDataInstance(instance, this.RBC_Payment_and_Contributions_headers);
        });

        // the ReportLayoutTabs components needs the tabs data in the state
        // (the tabs data can't be the return value of execQuery because the ReportLayout component needs execQuery to return an array of items)
        this.setState({
            tabsData: {
                ER_Processing_log: filteredRemittancesWithAdjustments_asObjects,
                ER_PL_Adjustments: filteredRemittancesWithAdjustments_asObjects,
                RBC_Payment_and_Contributions: sortedPayments_asObjects
            }
        });

        // the ReportLayout component needs execQuery to return an array of items.
        return filteredRemittancesWithAdjustments;
    }
}