import React, { useState } from 'react';
import { EInput } from '../../../../framework/components';
import { Select } from '../../../../framework/controls';
import useUser from '../../../../hooks/useUser';
import { Period } from '../../../../framework/utils';

/**
 * This component is used to choose a period to report earnings in the closed participation
 * The earnings can be reported in the closed participation to the end of the selected period
 * Only super Admin 
 */
const CloseEarningsPeriod = ({event}) => {
    const user = useUser();

    const [hasEarningsOnClosePP, setHasEarningsOnClosePP] = useState(Boolean(event.metadata?.openEarningsPeriod));
    const [selectedPeriod, setSelectedPeriod] = useState(event.metadata?.openEarningsPeriod);
    
    if (!user.isSuperAdmin || !Boolean(event.status.isClose?.())) return <></>;
    
    const periodOptions = Period.getPeriods(Period.create(event.effDt), null, true).map(period => (
        {key: period.text, text: period.text, value: period.text}
    ));

    const onSelectHasEarningsOnClosePP = (e) => {
        const value = e.target.checked;
        if(!value) {
            event.metadata = {...event.metadata, openEarningsPeriod: null};
            setSelectedPeriod(null);
        }
        setHasEarningsOnClosePP(value);
    }

    const selectPeriod = (period) => {
        event.metadata = {...event.metadata, openEarningsPeriod: period};
        setSelectedPeriod(period);
    }

    return (<>
        <div class="mt-2">
            <div> 
                <input
                    style={{marginRight: "5px"}}
                    type="checkbox"
                    checked={hasEarningsOnClosePP}
                    onChange={onSelectHasEarningsOnClosePP}
                />
                <label>Report earnings in the closed participation</label>
            </div>
            {hasEarningsOnClosePP && 
            <div class="mt-2 row g10 align-items-center">
                <label>Pensionable Earnings until : </label>
                <EInput name='openEarningsPeriod' instance={event.metadata} cn='col-3'>
                    <Select options={periodOptions} value={selectedPeriod} onChange={selectPeriod}/>
                </EInput>
            </div>}
        </div>
    </>)
}

export { CloseEarningsPeriod };