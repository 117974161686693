import YesNoDetailResolution from "../../framework/components/taskValidation/RemittanceDetailError/YesNoDetailResolution";
import MidMonthAdjustment from "../../views/financial/remittance/warningfixers/MidMonthAdjustments";

export const RemittanceMessageConfig = {
    default: {},
    // NOTE: We do not want to delete this (APP-1397), will be fixed in the future
    // midMonthEnr: {
        // fixer: <MidMonthAdjustment type="MID"/>   
    // },

    nonEnroll: {
        alwaysShow: true
    },
    midMonthRet: {
        fixer: <MidMonthAdjustment type='A60'/>
    },
    closeEarningAdj: {
        fixer : <YesNoDetailResolution 
            onYes={(participation, cmt) => participation.addEvent({code: 'adjTerm', cmt: cmt})} 
            onNo={(participation, cmt) => participation.addEvent({code: 'noAdjTerm', cmt: cmt})}
            message={'Has the adjustment been created?'}
        />,
        alwaysShow: true,
    },
    noEarningsAt60: {
        alwaysShow: true
    },

}