import React from 'react';
import Icon from '../../framework/controls/Icon';
import Button from '../../framework/controls/Button';

const FilterBadge = ({filter, onRemove, filterIcon = ''}) => {
    const iconValue = filterIcon + ' mr-1';

    return (
        <Button className={'btn btn-min no-mar'} >
            <Icon icon={iconValue} onClick={() => onRemove(filter.key)} />
            {filter.label || filter.value}
            <Icon icon="times-circle ml-1" onClick={() => onRemove(filter.key, filter.value)} />
        </Button>
    );
};

export default FilterBadge;