import { Service } from '../../framework'
import EventResults from '../../entities/search/EventSearch/EventResults';
import { EmployerService } from '..';

/**
 * @extends {Service}
 */
class EventSearch extends Service {
    constructor() {
        super(EventResults, 'Employments', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    /**
     * 
     * @param {object} parameters 
     * @param {number | undefined} page Default: 1
     * @param {number | undefined} pageSize Default: 15
     * @param {string} scrollDuration 
     * @param {string | undefined} scrollId 
     * @returns {Promise<EventResults>}
     */
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }

    /**
     * Recursively search until all results are returned
     * @param {object} searchParams
     * @returns {Promise<EventResults>}
     */
    async scrollSearch(searchParams) {
      const result = await this._scrollSearch(searchParams);
      return result;
    }

    async load(eventResults, options) {
        const documents = eventResults.documents.all || [];
        for (const document of documents) {
            const employer = await EmployerService.get(document.employerId);
            document.employer = employer;
        }
        return eventResults
    }
}

const instance = new EventSearch()
export default instance

